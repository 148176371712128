import React from 'react';
import { getActiveFilters, addFilter, removeFilter, clearFilters, getSourceAbbreviation } from '../utils/spellFilters';

function SpellFilters({ filters, setFilters, filterOptions }) {
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters(prevFilters => addFilter(prevFilters, name, value));
  };

  const handleRemoveFilter = (filterName, value) => {
    setFilters(prevFilters => removeFilter(prevFilters, filterName, value));
  };

  const handleClearFilters = () => {
    setFilters(clearFilters());
  };

  const renderFilterTag = (name, value) => {
    if (name === 'source') {
      return getSourceAbbreviation(value);
    }
    return value;
  };

  const renderFilterGroup = (name, options, label) => {
    const activeFilters = getActiveFilters(filters);
    return (
      <div className="filter-group" key={name}>
        <select name={name} value="" onChange={handleFilterChange}>
          <option value="">{label}</option>
          {options.map(option => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
        <div className="filter-tags">
          {activeFilters[name]?.map(value => (
            <span 
              key={value} 
              className="filter-tag"
              onClick={() => handleRemoveFilter(name, value)}
            >
              {renderFilterTag(name, value)}
              <button 
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveFilter(name, value);
                }}
              >
                &times;
              </button>
            </span>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="filters">
      {renderFilterGroup('class', filterOptions.classes, 'Class')}
      {renderFilterGroup('level', filterOptions.levels, 'Level')}
      {renderFilterGroup('school', filterOptions.schools, 'School')}
      {renderFilterGroup('components', filterOptions.components, 'Components')}
      {renderFilterGroup('concentration', ['Yes', 'No'], 'Concentration')}
      {renderFilterGroup('ritual', ['Yes', 'No'], 'Ritual')}
      {renderFilterGroup('source', filterOptions.sources, 'Source')}
      <button className="clear-filters" onClick={handleClearFilters}>Clear Filters</button>
    </div>
  );
}

export default SpellFilters;