import React, { useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/components/SpellTable.css';
import { formatUnits } from '../utils/formatUnits';
import { convertUnits } from '../utils/converUnits';
import PaginationControls from './PaginationControls';
import useResizableColumns from '../hooks/useResizableColumns';
import { updateSortConfig } from '../utils/sortSpells';

const CHECK_MARK = '✓';

const getShortSchoolName = (school) => {
  return school.substring(0, 3).toUpperCase();
};

const getFullSchoolName = (school) => {
  return school.charAt(0).toUpperCase() + school.slice(1);
};

const SpellTable = ({
  spells,
  selectedSpells,
  toggleItem,
  selectAll,
  areAllSelected,
  showShortDescriptions,
  convertToMetric,
  expandedRows,
  setExpandedRows,
  visibleColumns,
  renderIcons,
  emptyMessage = "No spells match the current criteria.",
  showRitualStyling = false,
  className = '',
  itemsPerPage: defaultItemsPerPage = 25,
  onItemsPerPageChange,
  sortConfig,
  setSortConfig,
  pagination = { itemsPerPage: 25, currentPage: 1 },
  setPagination = () => {},
  tabName = 'spellList',
}) => {
  const { itemsPerPage, currentPage } = pagination;

  const totalItems = spells.filter(spell => !spell.isSeparator).length;

  useEffect(() => {
    setPagination(prev => ({ ...prev, currentPage: 1 }));
  }, [spells, setPagination]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentSpells = spells.slice(startIndex, endIndex);

  const handlePageChange = (newPage) => {
    setPagination(prev => ({ ...prev, currentPage: newPage }));
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setPagination({ itemsPerPage: newItemsPerPage, currentPage: 1 });
  };

  const getSpellLevel = (level) => {
    if (level === 'cantrip') return 'Cantrip';
    return level.toString();
  };

  const getComponents = (spell) => {
    if (!spell.components) return '';
    
    const components = [];
    if (spell.components.verbal) components.push('V');
    if (spell.components.somatic) components.push('S');
    if (spell.components.material) {
      // Check if any material has a cost (GP or SP)
      const materialsStr = spell.components.materials_needed?.join(' ') || '';
      if (materialsStr.includes(' GP') || materialsStr.includes(' SP')) {
        components.push('M gp');
      } else {
        components.push('M');
      }
    }
    
    return components.join(', ');
  };

  const formatTableValue = (value) => {
    if (typeof value === 'string') {
      if (value.startsWith('1 reaction')) {
        return '1 reaction';
      }
      if (value.startsWith('Concentration,')) {
        return value.replace('Concentration, ', '');
      }
      if (value.includes('emanation')) {
        return value.replace(/(\d+)-?foot emanation/, '$1ft emanation')
                   .replace(/(\d+)-?ft emanation/, '$1ft emanation');
      }
    }
    return formatUnits(convertToMetric ? convertUnits(value) : value);
  };

  const formatShortDescription = (description) => {
    return convertToMetric ? convertUnits(description) : description;
  };

  const capitalizeFirstLetter = (string) => {
    if (!string || typeof string !== 'string') return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const toggleSpellDescription = useCallback((spellName, event) => {
    if (event.target.type === 'checkbox' || event.target.closest('.icon-container')) {
      return;
    }
    setExpandedRows(prev => ({
      ...prev,
      [spellName]: !prev[spellName]
    }));
  }, [setExpandedRows]);

  const isRowExpanded = useCallback((spellName) => {
    return showShortDescriptions || expandedRows[spellName];
  }, [showShortDescriptions, expandedRows]);

  const initialColumnWidths = {
    0: 20,   // Checkbox column
    1: 325,  // Name column (reduced from 330px)
    2: 45,   // Level column (increased from 40px)
    3: 50,   // School column
    4: 90,   // Components column
    5: 110,  // Casting Time column
    6: 100,  // Range column
    7: 110,  // Duration column
    8: 50,   // Concentration column
    9: 50    // Ritual column
  };

  const columns = [
    { key: 'checkbox', label: <input type="checkbox" checked={areAllSelected} onChange={(e) => selectAll(e.target.checked)} /> },
    { key: 'name', label: 'Name' },
    { key: 'level', label: 'Level' },
    { key: 'school', label: 'School' },
    { key: 'components', label: 'Components' },
    { key: 'castingTime', label: 'Casting Time' },
    { key: 'range', label: 'Range' },
    { key: 'duration', label: 'Duration' },
    { key: 'concentration', label: <span className="header-badge" title="Concentration">C.</span> },
    { key: 'ritual', label: <span className="header-badge" title="Ritual">R.</span> }
  ];

  const { columnWidths, handleResizeStart, resetColumnWidths, isResizing } = useResizableColumns(initialColumnWidths, tabName);

  const remainingSpells = spells.filter(spell => !spell.isSeparator).length - endIndex;
  const hasMoreSpells = remainingSpells > 0 && itemsPerPage !== totalItems;

  const requestSort = (key) => {
    const newSortConfig = updateSortConfig(sortConfig, key);
    setSortConfig(newSortConfig);
  };

  return (
    <div className={`spell-table-container ${className}`}>
      <div className="table-controls">
        <button 
          className="reset-columns-button"
          onClick={resetColumnWidths}
          title="Reset column widths"
        >
          Reset Columns
        </button>
      </div>
      <table className={isResizing ? 'resizing' : ''}>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th 
                key={column.key}
                style={{ width: `${columnWidths[index]}px` }}
                onClick={() => requestSort(column.key)}
                className={sortConfig?.key === column.key ? `sort-${sortConfig.direction}` : ''}
              >
                {column.label}
                <div
                  className="resize-handle"
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    handleResizeStart(index, e);
                  }}
                />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentSpells.length > 0 ? (
            <>
              {currentSpells.map((spell) => {
                if (spell.isSeparator) {
                  return (
                    <tr key="separator" className="separator-row">
                      <td colSpan={visibleColumns}></td>
                    </tr>
                  );
                }

                const isUnpreparedRitual = showRitualStyling && spell.ritual && !spell.isPrepared;
                
                return (
                  <React.Fragment key={spell.name}>
                    <tr 
                      className={`spell-row ${isRowExpanded(spell.name) ? 'expanded' : ''} ${isUnpreparedRitual ? 'ritual' : ''}`}
                      onClick={(event) => toggleSpellDescription(spell.name, event)}
                    >
                      <td style={{ width: `${columnWidths[0]}px` }} onClick={(e) => e.stopPropagation()}>
                        <input
                          type="checkbox"
                          checked={selectedSpells[spell.name] || false}
                          onChange={() => toggleItem(spell.name)}
                        />
                      </td>
                      <td className="spell-name-cell" style={{ width: `${columnWidths[1]}px` }}>
                        <Link to={`/spell/${encodeURIComponent(spell.name)}`}>{spell.name}</Link>
                        <div className="icon-container">
                          {renderIcons(spell)}
                        </div>
                      </td>
                      {isRowExpanded(spell.name) ? (
                        <td 
                          colSpan="8" 
                          className="short-description"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleSpellDescription(spell.name, e);
                          }}
                        >
                          {formatShortDescription(spell.shortDescription) || 'No short description available.'}
                        </td>
                      ) : (
                        <>
                          <td style={{ width: `${columnWidths[2]}px` }}>{getSpellLevel(spell.level)}</td>
                          <td style={{ width: `${columnWidths[3]}px` }}>
                            <span 
                              className={`school-badge school-${spell.school.toLowerCase()}`}
                              title={getFullSchoolName(spell.school)}
                            >
                              {getShortSchoolName(spell.school)}
                            </span>
                          </td>
                          <td style={{ width: `${columnWidths[4]}px` }}>{getComponents(spell)}</td>
                          <td style={{ width: `${columnWidths[5]}px` }}>{formatTableValue(spell.casting_time)}</td>
                          <td style={{ width: `${columnWidths[6]}px` }}>{formatTableValue(spell.range)}</td>
                          <td style={{ width: `${columnWidths[7]}px` }}>{formatTableValue(spell.duration)}</td>
                          <td style={{ width: `${columnWidths[8]}px` }}>
                            {spell.duration?.toLowerCase().includes('concentration') && (
                              <span className="check-mark">{CHECK_MARK}</span>
                            )}
                          </td>
                          <td style={{ width: `${columnWidths[9]}px` }}>
                            {spell.ritual && (
                              <span className="check-mark">{CHECK_MARK}</span>
                            )}
                          </td>
                        </>
                      )}
                    </tr>
                    {isRowExpanded(spell.name) && (
                      <tr 
                        className={`spell-properties-row ${isUnpreparedRitual ? 'ritual' : ''}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleSpellDescription(spell.name, e);
                        }}
                      >
                        <td style={{ width: `${columnWidths[0]}px` }}></td>
                        <td style={{ width: `${columnWidths[1]}px` }}></td>
                        <td style={{ width: `${columnWidths[2]}px` }}>{getSpellLevel(spell.level)}</td>
                        <td style={{ width: `${columnWidths[3]}px` }}>
                          <span 
                            className={`school-badge school-${spell.school.toLowerCase()}`}
                            title={getFullSchoolName(spell.school)}
                          >
                            {getShortSchoolName(spell.school)}
                          </span>
                        </td>
                        <td style={{ width: `${columnWidths[4]}px` }}>{getComponents(spell)}</td>
                        <td style={{ width: `${columnWidths[5]}px` }}>{formatTableValue(spell.casting_time)}</td>
                        <td style={{ width: `${columnWidths[6]}px` }}>{formatTableValue(spell.range)}</td>
                        <td style={{ width: `${columnWidths[7]}px` }}>{formatTableValue(spell.duration)}</td>
                        <td style={{ width: `${columnWidths[8]}px` }}>
                          {spell.duration?.toLowerCase().includes('concentration') && (
                            <span className="check-mark">{CHECK_MARK}</span>
                          )}
                        </td>
                        <td style={{ width: `${columnWidths[9]}px` }}>
                          {spell.ritual && (
                            <span className="check-mark">{CHECK_MARK}</span>
                          )}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
              {hasMoreSpells && (
                <tr className="remaining-spells-row">
                  <td colSpan={visibleColumns}>
                    {remainingSpells} more spell{remainingSpells !== 1 ? 's' : ''} available. Use the pagination controls below to view more.
                  </td>
                </tr>
              )}
            </>
          ) : (
            <tr className="empty-table-row">
              <td colSpan={visibleColumns}>{emptyMessage}</td>
            </tr>
          )}
        </tbody>
      </table>
      
      {spells.length > 0 && (
        <PaginationControls
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={handleItemsPerPageChange}
          totalItems={totalItems}
        />
      )}
    </div>
  );
};

export default SpellTable; 