import React from 'react';
import '../styles/components/NotificationPopup.css';

const NotificationPopup = ({ message, type = 'info' }) => {
  return (
    <div className={`notification-popup ${type}`} role="alert">
      <p>{message}</p>
    </div>
  );
};

export default NotificationPopup;
