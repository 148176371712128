import React from 'react';
import '../styles/components/SpellCounter.css';

const SpellCounter = ({ count }) => {
  return (
    <div className="spell-counter">
      <span className="spell-count">{count}</span>
      <span className="spell-count-label">{count === 1 ? 'Spell' : 'Spells'}</span>
    </div>
  );
};

export default SpellCounter;