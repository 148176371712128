import React, { useState, useEffect, useMemo, useCallback, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import SearchBar from './SearchBar';
import SpellFilters from './SpellFilters';
import useSpellFilters from '../hooks/useSpellFilters';
import useSortSpells from '../hooks/useSortSpells';
import useSearchSpells from '../hooks/useSearchSpells';
import useCheckboxSelection from '../hooks/useCheckboxSelection';
import useUnitConversion from '../hooks/useUnitConversion';
import { formatUnits } from '../utils/formatUnits';
import { convertUnits } from '../utils/converUnits';
import '../styles/components/SpellBook.css';
import { BookIcon, TrashcanIcon, RemoveSpellIcon, AllSpellsIcon, BackToTopIcon, NewInfoIcon } from '../data/Icons';
import ConfirmationPopup from './ConfirmationPopup';
import NotificationPopup from './NotificationPopup';
import SpellInfoBox from './SpellInfoBox';
import SpellCounter from './SpellCounter';
import { NotificationContext } from '../App';
import { useAuth } from '../AuthContext';
import { 
  updateUserSpellbooks, 
  removeSpellbook, 
  createSpellbook as createFirestoreSpellbook,
  getUserSpellbooks,
  getUserSettings,
  updateUserPreparedSpells,
  getUserSpellbookOrder,
  updateUserSpellbookOrder,
} from '../firebase/firestoreOperations';
import spellsData from '../data/spells.json';
import { debounce } from 'lodash';
import SpellTable from './SpellTable';
import SpellbookGenerator from './SpellbookGenerator';
import HowToUseBox from './HowToUseBox';

function SpellBook({
  filters,
  setFilters,
  selectedSpells,
  setSelectedSpells,
  searchTerm,
  setSearchTerm,
  convertToMetric,
  setConvertToMetric,
  spellbooks,
  setSpellbooks,
  addToSpellbook,
  createSpellbook,
  preparedSpells,
  setPreparedSpells,
  showShortDescriptions,
  setShowShortDescriptions,
  showRitualSpells,
  setShowRitualSpells,
  currentSpellbook,
  setCurrentSpellbook,
  preparedSpellsByBook,
  updatePreparedSpells,
  sortConfig,
  setSortConfig,
  pagination,
  setPagination
}) {
  const [newSpellbookName, setNewSpellbookName] = useState('');
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [spellbookToDelete, setSpellbookToDelete] = useState(null);
  const [spellbookOrder, setSpellbookOrder] = useState(() => {
    return JSON.parse(localStorage.getItem('spellbookOrder')) || ['My Spellbook'];
  });
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [selectedSpell, setSelectedSpell] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});
  const { addNotification } = useContext(NotificationContext);
  const { currentUser } = useAuth();
  const [showLoginWarning, setShowLoginWarning] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState(10);
  const [showGenerator, setShowGenerator] = useState(false);

  useEffect(() => {
    const loadSpellbookOrder = async () => {
      if (currentUser) {
        const order = await getUserSpellbookOrder(currentUser.uid);
        setSpellbookOrder(order);
      }
    };
    loadSpellbookOrder();
  }, [currentUser]);

  const updateVisibleColumns = useCallback(debounce(() => {
    const width = window.innerWidth;
    let count = 10;

    if (width <= 600) count = 3;
    else if (width <= 700) count = 4;
    else if (width <= 800) count = 5;
    else if (width <= 900) count = 6;
    else if (width <= 1000) count = 7;
    else if (width <= 1100) count = 8;
    else if (width <= 1200) count = 9;

    setVisibleColumns(count);
  }, 200), []);

  useEffect(() => {
    updateVisibleColumns();
    window.addEventListener('resize', updateVisibleColumns);
    return () => {
      window.removeEventListener('resize', updateVisibleColumns);
      updateVisibleColumns.cancel();
    };
  }, [updateVisibleColumns]);

  useEffect(() => {
    // Only ensure "My Spellbook" exists if it's the only spellbook
    if (Object.keys(spellbooks).length === 0) {
      setSpellbooks(prevSpellbooks => ({
        'My Spellbook': [],
        ...prevSpellbooks
      }));
    }

    // Update spellbookOrder to include any new spellbooks while maintaining existing order
    setSpellbookOrder(prevOrder => {
      const allSpellbooks = Object.keys(spellbooks);
      const newOrder = [...prevOrder];
      
      // Add any new spellbooks to the end of the order
      allSpellbooks.forEach(book => {
        if (!newOrder.includes(book)) {
          newOrder.push(book);
        }
      });
      
      // Remove any spellbooks that no longer exist
      const finalOrder = newOrder.filter(book => allSpellbooks.includes(book));
      
      // Save the order to localStorage
      localStorage.setItem('spellbookOrder', JSON.stringify(finalOrder));
      
      return finalOrder;
    });

    if (!currentSpellbook || !spellbooks[currentSpellbook]) {
      const firstAvailableSpellbook = Object.keys(spellbooks)[0];
      if (firstAvailableSpellbook) {
        setCurrentSpellbook(firstAvailableSpellbook);
        localStorage.setItem('lastActiveSpellbook', firstAvailableSpellbook);
      }
    }

    // Show login warning for non-logged-in users
    if (!currentUser) {
      setShowLoginWarning(true);
    }
  }, [spellbooks, currentSpellbook, setSpellbooks, currentUser]);

  const {
    filteredSpells,
    uniqueLevels,
    uniqueSchools,
    uniqueClasses,
    componentOptions,
    uniqueSources,
    handleFilterChange,
    handleRemoveFilter,
    handleClearFilters,
    getActiveFilters
  } = useSpellFilters(spellbooks[currentSpellbook] || [], filters, setFilters);

  const { sortedSpells, requestSort } = useSortSpells(filteredSpells, sortConfig, setSortConfig);
  const searchedSpells = useSearchSpells(sortedSpells, searchTerm);
  const { toggleItem, selectAll, areAllSelected, clearSelection } = useCheckboxSelection(searchedSpells, selectedSpells, setSelectedSpells);
  const { toggleConversion, convertIfNeeded } = useUnitConversion(convertToMetric, setConvertToMetric);

  const isAnySpellSelected = Object.values(selectedSpells).some(value => value);

  const handleCreateSpellbook = async (e) => {
    e.preventDefault();
    
    if (!currentUser) {
      addNotification('Please log in to create new spellbooks', 'warning');
      return;
    }

    // Case-insensitive check for existing spellbook names
    const existingNames = Object.keys(spellbooks).map(name => name.toLowerCase());
    if (currentUser && newSpellbookName && !existingNames.includes(newSpellbookName.toLowerCase()) && newSpellbookName.length <= 25) {
      await createFirestoreSpellbook(currentUser.uid, newSpellbookName);
      createSpellbook(newSpellbookName);
      
      const newOrder = [...spellbookOrder, newSpellbookName];
      setSpellbookOrder(newOrder);
      
      if (currentUser) {
        await updateUserSpellbookOrder(currentUser.uid, newOrder);
      } else {
        localStorage.setItem('spellbookOrder', JSON.stringify(newOrder));
      }
      
      setNewSpellbookName('');
      setCurrentSpellbook(newSpellbookName);
      addNotification(`Spellbook "${newSpellbookName}" created`, 'success');
    } else if (existingNames.includes(newSpellbookName.toLowerCase())) {
      addNotification('A spellbook with this name already exists', 'error');
    } else if (newSpellbookName.length > 25) {
      addNotification('Spellbook name must be 25 characters or less', 'error');
    }
  };

  const handleRemoveSpellbook = (name) => {
    if (Object.keys(spellbooks).length > 1) {
      setSpellbookToDelete(name);
      setIsConfirmationOpen(true);
    } else {
      addNotification("Cannot delete the last spellbook", "error");
    }
  };

  const confirmRemoveSpellbook = async () => {
    if (spellbookToDelete && Object.keys(spellbooks).length > 1) {
      if (currentUser) {
        await removeSpellbook(currentUser.uid, spellbookToDelete);
        
        setSpellbooks(prevSpellbooks => {
          const updatedSpellbooks = { ...prevSpellbooks };
          delete updatedSpellbooks[spellbookToDelete];
          
          // If we're deleting the current spellbook, switch to another one
          if (currentSpellbook === spellbookToDelete) {
            const newCurrentSpellbook = Object.keys(updatedSpellbooks)[0];
            setCurrentSpellbook(newCurrentSpellbook);
            localStorage.setItem('lastActiveSpellbook', newCurrentSpellbook);
          }
          
          return updatedSpellbooks;
        });
        
        const newOrder = spellbookOrder.filter(book => book !== spellbookToDelete);
        setSpellbookOrder(newOrder);
        
        if (currentUser) {
          await updateUserSpellbookOrder(currentUser.uid, newOrder);
        } else {
          localStorage.setItem('spellbookOrder', JSON.stringify(newOrder));
        }
        
        addNotification(`Spellbook "${spellbookToDelete}" deleted`, 'success');
      } else {
        // Handle deletion for non-logged-in users
        setSpellbooks(prevSpellbooks => {
          const updatedSpellbooks = { ...prevSpellbooks };
          delete updatedSpellbooks[spellbookToDelete];
          localStorage.setItem('guestSpellbooks', JSON.stringify(updatedSpellbooks));
          return updatedSpellbooks;
        });
      }
    } else {
      addNotification("Cannot delete the last spellbook", "error");
    }
    setIsConfirmationOpen(false);
    setSpellbookToDelete(null);
  };

  const cancelRemoveSpellbook = () => {
    setIsConfirmationOpen(false);
    setSpellbookToDelete(null);
  };

  const getSpellLevel = (level) => {
    return level === 0 ? 'Cantrip' : level.toString();
  };

  const getComponents = (spell) => {
    const components = [];
    if (spell.components.verbal) components.push('V');
    if (spell.components.somatic) components.push('S');
    if (spell.components.material) components.push('M');
    return components.join(', ');
  };

  const formatTableValue = (value) => {
    if (typeof value === 'string') {
      if (value.startsWith('1 reaction')) {
        return '1 reaction';
      }
      if (value.startsWith('Concentration,')) {
        return value.replace('Concentration, ', '');
      }
    }
    return formatUnits(convertIfNeeded(value));
  };

  // Add this new function to handle short description conversion
  const formatShortDescription = (description) => {
    return convertToMetric ? convertUnits(description) : description;
  };

  const handleAddToSpellbook = (spellName, selectedSpellbook) => {
    if (selectedSpellbook) {
      const spellToAdd = spellsData.find(spell => spell.name === spellName);
      if (spellToAdd) {
        setSpellbooks(prevSpellbooks => {
          const updatedSpellbooks = {
            ...prevSpellbooks,
            [selectedSpellbook]: [...(prevSpellbooks[selectedSpellbook] || []), spellToAdd]
          };
          
          if (currentUser) {
            updateUserSpellbooks(currentUser.uid, updatedSpellbooks);
          } else {
            localStorage.setItem('guestSpellbooks', JSON.stringify(updatedSpellbooks));
          }
          
          return updatedSpellbooks;
        });
        
        addNotification(`${spellName} has been added to ${selectedSpellbook}`, 'success');
      }
    }
  };

  const isSpellPrepared = useCallback((spellName) => {
    const currentBookPreparedSpells = preparedSpellsByBook[currentSpellbook] || [];
    return currentBookPreparedSpells.some(s => s.name === spellName);
  }, [preparedSpellsByBook, currentSpellbook]);

  const handlePrepareSpell = (spell) => {
    const currentPreparedSpells = preparedSpellsByBook[currentSpellbook] || [];
    
    if (isSpellPrepared(spell.name)) {
      // Unprepare the spell
      const newPreparedSpells = currentPreparedSpells.filter(s => s.name !== spell.name);
      updatePreparedSpells(currentSpellbook, newPreparedSpells);
      addNotification(`${spell.name} has been unprepared from ${currentSpellbook}`, 'info');
    } else {
      // Prepare the spell
      const newPreparedSpells = [...currentPreparedSpells, spell];
      updatePreparedSpells(currentSpellbook, newPreparedSpells);
      addNotification(`${spell.name} has been prepared in ${currentSpellbook}`, 'success');
    }
  };

  const handleSpellbookClick = (book) => {
    setCurrentSpellbook(book);
    localStorage.setItem('lastActiveSpellbook', book);
  };

  const handlePrepareSelectedSpells = () => {
    const selectedSpellsList = Object.keys(selectedSpells).filter(spell => selectedSpells[spell]);
    const spellsToPrepare = spellbooks[currentSpellbook].filter(spell => selectedSpellsList.includes(spell.name));
    
    const currentPreparedSpells = preparedSpellsByBook[currentSpellbook] || [];
    const newPreparedSpells = [...currentPreparedSpells, ...spellsToPrepare];
    
    updatePreparedSpells(currentSpellbook, newPreparedSpells);
    clearSelection();
    addNotification(`${spellsToPrepare.length} spell${spellsToPrepare.length > 1 ? 's' : ''} prepared`, 'success');
  };

  const handleRemoveSelectedSpells = () => {
    const selectedSpellsList = Object.keys(selectedSpells).filter(spell => selectedSpells[spell]);
    const updatedSpellbook = spellbooks[currentSpellbook].filter(spell => !selectedSpellsList.includes(spell.name));
    
    setSpellbooks(prevSpellbooks => {
      const newSpellbooks = {
        ...prevSpellbooks,
        [currentSpellbook]: updatedSpellbook
      };
      
      if (currentUser) {
        updateUserSpellbooks(currentUser.uid, newSpellbooks);
      } else {
        // For non-logged-in users, update localStorage
        localStorage.setItem('guestSpellbooks', JSON.stringify(newSpellbooks));
      }
      
      return newSpellbooks;
    });

    clearSelection();
    addNotification(`${selectedSpellsList.length} spell${selectedSpellsList.length > 1 ? 's' : ''} removed from ${currentSpellbook}`, 'success');
  };

  const handleScroll = useCallback(() => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setShowBackToTop(scrollTop > 300);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleRemoveSpellFromBook = useCallback(async (spellName) => {
    try {
      const updatedSpellbooks = {
        ...spellbooks,
        [currentSpellbook]: spellbooks[currentSpellbook].filter(spell => spell.name !== spellName)
      };

      setSpellbooks(updatedSpellbooks);

      if (currentUser) {
        await updateUserSpellbooks(currentUser.uid, updatedSpellbooks);
      } else {
        localStorage.setItem('guestSpellbooks', JSON.stringify(updatedSpellbooks));
      }

      addNotification(`${spellName} removed from ${currentSpellbook}`, 'success');
    } catch (error) {
      console.error('Error removing spell from spellbook:', error);
      addNotification(`Failed to remove ${spellName} from ${currentSpellbook}`, 'error');
    }
  }, [currentUser, currentSpellbook, spellbooks, addNotification]);

  const handleSpellInfoClick = (spell) => {
    setSelectedSpell(spell);
  };

  useEffect(() => {
    setExpandedRows({});
  }, [showShortDescriptions]);

  const toggleSpellDescription = useCallback((spellName, event) => {
    // Prevent toggling if the click was on a checkbox or icon
    if (event.target.type === 'checkbox' || event.target.closest('.info-icon, .remove-spell-icon, .prepare-spell-icon')) {
      return;
    }
    setExpandedRows(prev => ({
      ...prev,
      [spellName]: !prev[spellName]
    }));
  }, []);

  const isRowExpanded = useCallback((spellName) => {
    return showShortDescriptions || expandedRows[spellName];
  }, [showShortDescriptions, expandedRows]);

  // Add this function:
  const handleConvertToMetricChange = (event) => {
    setConvertToMetric(event.target.checked);
  };

  // Add a new function to handle removing spells for non-logged-in users
  const handleRemoveSpellFromBookNonLogged = (spellName) => {
    setSpellbooks(prevSpellbooks => {
      const updatedSpellbooks = {
        ...prevSpellbooks,
        'My Spellbook': prevSpellbooks['My Spellbook'].filter(spell => spell.name !== spellName)
      };
      localStorage.setItem('guestSpellbooks', JSON.stringify(updatedSpellbooks));
      return updatedSpellbooks;
    });
    addNotification(`${spellName} removed from My Spellbook`, 'success');
  };

  const renderFilterGroup = (name, options, label) => {
    const activeFilters = getActiveFilters(filters);
    return (
      <div className="filter-group">
        <select name={name} value="" onChange={handleFilterChange}>
          <option value="">{label}</option>
          {options.map(option => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
        <div className="filter-tags">
          {activeFilters[name] && activeFilters[name].map(value => (
            <span 
              key={value} 
              className="filter-tag"
              onClick={() => handleRemoveFilter(name, value)}
            >
              {value}
              <button 
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveFilter(name, value);
                }}
              >
                &times;
              </button>
            </span>
          ))}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (currentUser) {
      const fetchUserData = async () => {
        const userSpellbooks = await getUserSpellbooks(currentUser.uid);
        setSpellbooks(userSpellbooks);

        const userSettings = await getUserSettings(currentUser.uid);
        setShowShortDescriptions(userSettings.showShortDescriptions || false);
        setShowRitualSpells(userSettings.showRitualSpells || false);
        setConvertToMetric(userSettings.convertToMetric || false);
      };
      fetchUserData();
    } else {
      // Load spellbooks from localStorage for non-logged-in users
      const guestSpellbooks = JSON.parse(localStorage.getItem('guestSpellbooks')) || { 'My Spellbook': [] };
      setSpellbooks(guestSpellbooks);
    }
  }, [currentUser]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const renderIcons = (spell) => {
    return (
      <>
        <span 
          className="info-icon"
          onClick={(e) => {
            e.stopPropagation();
            handleSpellInfoClick(spell);
          }}
          title="Spell Info"
        >
          <NewInfoIcon />
        </span>
        <span 
          className="remove-spell-icon"
          onClick={(e) => {
            e.stopPropagation();
            handleRemoveSpellFromBook(spell.name);
          }}
          title="Remove from Spellbook"
        >
          <RemoveSpellIcon />
        </span>
        <span 
          className={`prepare-spell-icon ${isSpellPrepared(spell.name) ? 'prepared' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            handlePrepareSpell(spell);
          }}
          title={isSpellPrepared(spell.name) ? "Unprepare Spell" : "Prepare Spell"}
        >
          <AllSpellsIcon />
        </span>
      </>
    );
  };

  useEffect(() => {
    if (!spellbooks[currentSpellbook]) {
      const defaultSpellbook = Object.keys(spellbooks)[0] || 'My Spellbook';
      setCurrentSpellbook(defaultSpellbook);
      localStorage.setItem('lastActiveSpellbook', defaultSpellbook);
    }
  }, [spellbooks, currentSpellbook]);

  const handleDragStart = (e, book) => {
    e.dataTransfer.setData('text/plain', book);
    e.currentTarget.classList.add('dragging');
  };

  const handleDragEnd = (e) => {
    e.currentTarget.classList.remove('dragging');
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    const dragTab = document.querySelector('.dragging');
    if (!dragTab) return;

    const tabsList = dragTab.parentNode;
    const siblings = [...tabsList.querySelectorAll('.spellbook-item:not(.dragging)')];
    
    const afterElement = siblings.reduce((closest, sibling) => {
      const box = sibling.getBoundingClientRect();
      const offset = e.clientX - box.left - box.width / 2;
      
      if (offset < 0 && offset > closest.offset) {
        return { offset: offset, element: sibling };
      } else {
        return closest;
      }
    }, { offset: Number.NEGATIVE_INFINITY }).element;

    if (afterElement) {
      tabsList.insertBefore(dragTab, afterElement);
    } else {
      tabsList.appendChild(dragTab);
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    const draggedBook = e.dataTransfer.getData('text/plain');
    const dropZone = e.currentTarget.closest('.spellbook-item');
    const tabsList = dropZone.parentNode;
    const newOrder = [...tabsList.querySelectorAll('.spellbook-item')].map(item => 
      item.getAttribute('data-book')
    );
    
    setSpellbookOrder(newOrder);
    
    if (currentUser) {
      await updateUserSpellbookOrder(currentUser.uid, newOrder);
    } else {
      localStorage.setItem('spellbookOrder', JSON.stringify(newOrder));
    }
  };

  const handleGenerateSpellbook = (name, spells) => {
    if (currentUser && !spellbooks[name]) {
      setSpellbooks(prevSpellbooks => {
        const newSpellbooks = {
          ...prevSpellbooks,
          [name]: spells
        };
        
        if (currentUser) {
          updateUserSpellbooks(currentUser.uid, newSpellbooks);
        }
        
        return newSpellbooks;
      });
      
      // Update spellbook order
      const newOrder = [...spellbookOrder, name];
      setSpellbookOrder(newOrder);
      
      if (currentUser) {
        updateUserSpellbookOrder(currentUser.uid, newOrder);
      }
      
      setCurrentSpellbook(name);
      addNotification(`Generated spellbook "${name}" with ${spells.length} spells`, 'success');
    } else {
      addNotification('Please log in to create spellbooks', 'warning');
    }
  };

  return (
    <div className="spell-list">
      <HowToUseBox pageType="spellBook" />
      {!currentUser && (
        <div className="login-warning">
          <p>You are not logged in. Your spells in "My Spellbook" will be lost when you refresh the page. Please log in to save your spells and create multiple spellbooks.</p>
        </div>
      )}
      
      <div className="spellbook-management">
        <form onSubmit={handleCreateSpellbook} className="create-spellbook-form">
          <div className="create-section">
            <input
              type="text"
              value={newSpellbookName}
              onChange={(e) => setNewSpellbookName(e.target.value.slice(0, 25))}
              placeholder="New spellbook name"
              maxLength={25}
            />
            <button type="submit">Create</button>
          </div>
          <button 
            type="button" 
            onClick={() => setShowGenerator(true)}
            className="generate-button"
          >
            Spellbook
          </button>
        </form>
        
        {showGenerator && (
          <>
            <div className="blur-overlay" onClick={() => setShowGenerator(false)}></div>
            <div className="generator-modal">
              <SpellbookGenerator
                onGenerate={handleGenerateSpellbook}
                onClose={() => setShowGenerator(false)}
                spellbooks={spellbooks}
              />
            </div>
          </>
        )}
        
        <div className="spellbook-list">
          {spellbookOrder.map((book) => (
            <div 
              key={book} 
              className={`spellbook-item ${currentSpellbook === book ? 'active' : ''}`}
              draggable="true"
              data-book={book}
              onDragStart={(e) => handleDragStart(e, book)}
              onDragEnd={handleDragEnd}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <button
                className={`spellbook-select ${currentSpellbook === book ? 'active' : ''}`}
                onClick={() => handleSpellbookClick(book)}
              >
                <span className={`book-icon ${currentSpellbook === book ? 'active' : ''}`}>
                  <BookIcon />
                </span>
                {book}
                <span className="spell-count">({spellbooks[book] ? spellbooks[book].length : 0})</span>
              </button>
              <button
                className="spellbook-remove"
                onClick={() => handleRemoveSpellbook(book)}
                title={`Delete ${book}`}
              >
                <TrashcanIcon title="" aria-hidden="true" />
              </button>
            </div>
          ))}
        </div>
      </div>
      
      <div className="search-and-conversion">
        <div className="search-and-count">
          <SearchBar searchTerm={searchTerm} onSearchChange={setSearchTerm} />
          <SpellCounter count={searchedSpells.length} />
        </div>
        <div className="checkbox-container">
          <label>
            <input
              type="checkbox"
              checked={convertToMetric}
              onChange={handleConvertToMetricChange}
            />
            Convert to Metric
          </label>
          <label>
            <input
              type="checkbox"
              checked={showShortDescriptions}
              onChange={(e) => setShowShortDescriptions(e.target.checked)}
            />
            Show Short Descriptions
          </label>
        </div>
      </div>
      
      <SpellFilters
        filters={filters}
        setFilters={setFilters}
        filterOptions={{
          classes: uniqueClasses,
          levels: uniqueLevels,
          schools: uniqueSchools,
          components: componentOptions,
          sources: uniqueSources
        }}
      />

      <SpellTable
        spells={searchedSpells}
        selectedSpells={selectedSpells}
        toggleItem={toggleItem}
        selectAll={selectAll}
        areAllSelected={areAllSelected}
        showShortDescriptions={showShortDescriptions}
        convertToMetric={convertToMetric}
        expandedRows={expandedRows}
        setExpandedRows={setExpandedRows}
        visibleColumns={visibleColumns}
        renderIcons={renderIcons}
        emptyMessage="No spells in this spellbook."
        className="spellbook-table"
        sortConfig={sortConfig}
        setSortConfig={setSortConfig}
        pagination={pagination}
        setPagination={setPagination}
        tabName="spellBook"
      />
      
      {isAnySpellSelected && (
        <div className="floating-buttons">
          <button 
            onClick={handlePrepareSelectedSpells} 
            className="floating-button prepare-button"
          >
            Prepare Selected Spells
          </button>
          <button 
            onClick={handleRemoveSelectedSpells} 
            className="floating-button remove-button"
          >
            Remove Selected from Spellbook
          </button>
        </div>
      )}

      <ConfirmationPopup
        isOpen={isConfirmationOpen}
        message={`Are you sure you want to delete the spellbook "${spellbookToDelete}"? This action cannot be undone.`}
        onConfirm={confirmRemoveSpellbook}
        onCancel={cancelRemoveSpellbook}
      />
      
      {showBackToTop && (
        <button className="back-to-top" onClick={scrollToTop}>
          <BackToTopIcon />
        </button>
      )}
      
      {selectedSpell && (
        <>
          <div className="blur-overlay"></div>
          <SpellInfoBox
            spell={selectedSpell}
            onClose={() => setSelectedSpell(null)}
            convertToMetric={convertToMetric}
          />
        </>
      )}
    </div>
  );
}

export default SpellBook;
