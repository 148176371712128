export const sortSpells = (spells, { key, direction }) => {
  const sortedSpells = [...spells];

  sortedSpells.sort((a, b) => {
    // Handle special cases for components and casting time
    if (key === 'components') {
      const getComponentString = (spell) => {
        const components = [];
        if (spell.components?.verbal) components.push('V');
        if (spell.components?.somatic) components.push('S');
        if (spell.components?.material) components.push('M');
        return components.join(', ');
      };
      
      const aComponents = getComponentString(a);
      const bComponents = getComponentString(b);
      
      return direction === 'asc' 
        ? aComponents.localeCompare(bComponents)
        : bComponents.localeCompare(aComponents);
    }

    if (key === 'castingTime') {
      const aCastingTime = a.casting_time || '';
      const bCastingTime = b.casting_time || '';
      
      return direction === 'asc'
        ? aCastingTime.localeCompare(bCastingTime)
        : bCastingTime.localeCompare(aCastingTime);
    }

    // Handle level sorting with cantrips as level 0
    if (key === 'level') {
      const getNumericLevel = (spell) => {
        if (spell.level === 'cantrip') return 0;
        return typeof spell.level === 'number' ? spell.level : parseInt(spell.level) || 0;
      };

      const aLevel = getNumericLevel(a);
      const bLevel = getNumericLevel(b);

      if (aLevel === bLevel) {
        // If levels are equal, sort by name
        return direction === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      }

      return direction === 'asc' 
        ? aLevel - bLevel 
        : bLevel - aLevel;
    }

    // Handle concentration sorting
    if (key === 'concentration') {
      const aConcentration = a.duration?.toLowerCase().includes('concentration') || false;
      const bConcentration = b.duration?.toLowerCase().includes('concentration') || false;
      
      // Sort true values first by default (desc)
      return direction === 'desc'
        ? aConcentration === bConcentration ? 0 : aConcentration ? -1 : 1
        : aConcentration === bConcentration ? 0 : aConcentration ? 1 : -1;
    }

    // Handle ritual sorting
    if (key === 'ritual') {
      const aRitual = a.ritual === true;
      const bRitual = b.ritual === true;
      
      // Sort true values first by default (desc)
      return direction === 'desc'
        ? aRitual === bRitual ? 0 : aRitual ? -1 : 1
        : aRitual === bRitual ? 0 : aRitual ? 1 : -1;
    }

    // Handle null or undefined values
    if (a[key] == null) return direction === 'asc' ? -1 : 1;
    if (b[key] == null) return direction === 'asc' ? 1 : -1;

    // Default sorting for other columns
    if (typeof a[key] === 'string') {
      return direction === 'asc'
        ? a[key].localeCompare(b[key])
        : b[key].localeCompare(a[key]);
    }

    return direction === 'asc' ? a[key] - b[key] : b[key] - a[key];
  });

  return sortedSpells;
};

export const updateSortConfig = (currentConfig, key) => {
  // For concentration and ritual, we want to show true values first on first click
  if (key === 'concentration' || key === 'ritual') {
    if (currentConfig.key === key) {
      return {
        key,
        direction: currentConfig.direction === 'desc' ? 'asc' : 'desc',
      };
    }
    return { key, direction: 'desc' }; // Start with true values on top
  }

  // Default behavior for other columns
  if (currentConfig.key === key) {
    return {
      key,
      direction: currentConfig.direction === 'asc' ? 'desc' : 'asc',
    };
  }
  return { key, direction: 'asc' };
};
