import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/pages/HomePage.css';
import { BookIcon, AllSpellsIcon, PrepareSpellIcon } from '../data/Icons';

function HomePage() {
  return (
    <div className="home-page">
      <header className="hero">
        <div className="hero-content">
          <div className="hero-text">
            <h1>Master the Art of Spellcasting</h1>
            <p>Your comprehensive D&D 5e spell companion for seamless magic management</p>
            <div className="hero-buttons">
              <Link to="/spells" className="hero-button primary">
                Explore Spells
              </Link>
              <Link to="/login" className="hero-button secondary">
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </header>

      <div className="home-content">
        <section className="features">
          <h2>Features</h2>
          <div className="feature-grid">
            <div className="feature-item">
              <AllSpellsIcon />
              <h3>Comprehensive Spell List</h3>
              <p>Browse and search through a vast collection of D&D spells</p>
            </div>
            <div className="feature-item">
              <BookIcon />
              <h3>Spellbook Management</h3>
              <p>Create and organize multiple spellbooks for your characters</p>
            </div>
            <div className="feature-item">
              <PrepareSpellIcon />
              <h3>Spell Preparation</h3>
              <p>Easily prepare and track your daily spells</p>
            </div>
          </div>
        </section>

        <section className="cta">
          <h2>Ready to Enhance Your Spellcasting?</h2>
          <Link to="/spells" className="cta-button">Explore Spells</Link>
        </section>

        <section className="about">
          <h2>About D&D Spell Manager</h2>
          <p>D&D Spell Manager is a powerful tool designed to help Dungeons & Dragons players and Dungeon Masters manage their spells efficiently. Whether you're a novice wizard or an experienced archmage, our app provides the features you need to keep your magic organized and at your fingertips.</p>
        </section>
      </div>
    </div>
  );
}

export default HomePage;

