import React, { useState, useEffect } from 'react';
import { changePassword } from '../firebase/authFunctions';
import '../styles/components/PasswordChangeForm.css';
import { ClosedEyeIcon, OpenEyeIcon } from '../data/Icons';

function PasswordChangeForm() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    checkPasswordStrength(newPassword);
  }, [newPassword]);

  const checkPasswordStrength = (password) => {
    const minLength = 8;
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (password.length >= minLength && hasNumber && hasSpecialChar) {
      setPasswordStrength('strong');
    } else if (password.length >= minLength && (hasNumber || hasSpecialChar)) {
      setPasswordStrength('medium');
    } else if (password.length > 0) {
      setPasswordStrength('weak');
    } else {
      setPasswordStrength('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (newPassword !== confirmPassword) {
      setError("New passwords don't match");
      return;
    }

    if (passwordStrength !== 'strong') {
      setError('New password does not meet the required criteria');
      return;
    }

    try {
      await changePassword(currentPassword, newPassword);
      setSuccess('Password changed successfully');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setPasswordStrength('');
    } catch (error) {
      setError(error.message);
    }
  };

  const handlePasswordVisibility = (event, setVisibility) => {
    if (event.type === 'mousedown' || event.type === 'touchstart') {
      setVisibility(true);
    } else if (event.type === 'mouseup' || event.type === 'mouseleave' || event.type === 'touchend') {
      setVisibility(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="password-change-form">
      <h3>Change Password</h3>
      {error && <div className="error-message">{error}</div>}
      {success && <div className="success-message">{success}</div>}
      <div className="input-group">
        <label htmlFor="currentPassword">Current Password</label>
        <div className="password-input">
          <input
            type={showCurrentPassword ? "text" : "password"}
            id="currentPassword"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
          />
          <button
            type="button"
            className="password-toggle"
            onMouseDown={(e) => handlePasswordVisibility(e, setShowCurrentPassword)}
            onMouseUp={(e) => handlePasswordVisibility(e, setShowCurrentPassword)}
            onMouseLeave={(e) => handlePasswordVisibility(e, setShowCurrentPassword)}
            onTouchStart={(e) => handlePasswordVisibility(e, setShowCurrentPassword)}
            onTouchEnd={(e) => handlePasswordVisibility(e, setShowCurrentPassword)}
          >
            {showCurrentPassword ? <OpenEyeIcon /> : <ClosedEyeIcon />}
          </button>
        </div>
      </div>
      <div className="input-group">
        <label htmlFor="newPassword">New Password</label>
        <div className="password-input">
          <input
            type={showNewPassword ? "text" : "password"}
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <button
            type="button"
            className="password-toggle"
            onMouseDown={(e) => handlePasswordVisibility(e, setShowNewPassword)}
            onMouseUp={(e) => handlePasswordVisibility(e, setShowNewPassword)}
            onMouseLeave={(e) => handlePasswordVisibility(e, setShowNewPassword)}
            onTouchStart={(e) => handlePasswordVisibility(e, setShowNewPassword)}
            onTouchEnd={(e) => handlePasswordVisibility(e, setShowNewPassword)}
          >
            {showNewPassword ? <OpenEyeIcon /> : <ClosedEyeIcon />}
          </button>
        </div>
        <div className={`password-strength ${passwordStrength}`}>
          {passwordStrength && `Password strength: ${passwordStrength}`}
        </div>
        <div className="password-criteria">
          Password must be at least 8 characters long, contain 1 number and 1 special character.
        </div>
      </div>
      <div className="input-group">
        <label htmlFor="confirmPassword">Confirm New Password</label>
        <div className="password-input">
          <input
            type={showConfirmPassword ? "text" : "password"}
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <button
            type="button"
            className="password-toggle"
            onMouseDown={(e) => handlePasswordVisibility(e, setShowConfirmPassword)}
            onMouseUp={(e) => handlePasswordVisibility(e, setShowConfirmPassword)}
            onMouseLeave={(e) => handlePasswordVisibility(e, setShowConfirmPassword)}
            onTouchStart={(e) => handlePasswordVisibility(e, setShowConfirmPassword)}
            onTouchEnd={(e) => handlePasswordVisibility(e, setShowConfirmPassword)}
          >
            {showConfirmPassword ? <OpenEyeIcon /> : <ClosedEyeIcon />}
          </button>
        </div>
      </div>
      <button type="submit" className="submit-btn">Change Password</button>
    </form>
  );
}

export default PasswordChangeForm;
