import React from 'react';
import { MaterialIcon } from '../data/Icons';
import { convertUnits } from '../utils/converUnits';
import { formatSpellText } from '../utils/textFormatting';
import '../styles/components/SpellContent.css';

const SpellContent = ({ spell, convertToMetric }) => {
  if (!spell) return null;

  const getComponents = (spell) => {
    const components = [];
    if (spell.components.verbal) components.push('V');
    if (spell.components.somatic) components.push('S');
    if (spell.components.material) components.push('M');
    return components.join(', ');
  };

  const convertIfNeeded = (text) => {
    return convertToMetric ? convertUnits(text) : text;
  };

  const capitalizeClasses = (classes) => {
    if (!classes || !Array.isArray(classes)) return 'No classes specified';
    return classes
      .map(className => 
        typeof className === 'string' 
          ? className.charAt(0).toUpperCase() + className.slice(1)
          : ''
      )
      .filter(Boolean)
      .join(', ') || 'No classes specified';
  };

  const getLevelDisplay = () => {
    if (spell.level === 'cantrip') {
      const capitalizedSchool = spell.school.charAt(0).toUpperCase() + spell.school.slice(1);
      return `Cantrip, ${capitalizedSchool}`;
    }
    return `Level ${spell.level}, ${spell.school}`;
  };

  return (
    <div className="spell-content">
      <h2 className="spell-content__title">{spell.name}</h2>
      <p className="spell-content__subtitle">
        {getLevelDisplay()}
      </p>

      <div className="spell-content__properties">
        <div className="property-group">
          <strong>Casting Time</strong>
          <span>:</span>
          <span className="property-value">{spell.casting_time}</span>
        </div>
        <div className="property-group">
          <strong>Range</strong>
          <span>:</span>
          <span className="property-value">{convertIfNeeded(spell.range)}</span>
        </div>
        <div className="property-group">
          <strong>Components</strong>
          <span>:</span>
          <span className="property-value">{getComponents(spell)}</span>
        </div>
        <div className="property-group">
          <strong>Duration</strong>
          <span>:</span>
          <span className="property-value">{convertIfNeeded(spell.duration)}</span>
        </div>
        {spell.ritual && (
          <div className="property-group">
            <strong>Ritual</strong>
            <span>:</span>
            <span className="property-value">Yes</span>
          </div>
        )}
        {spell.concentration && (
          <div className="property-group">
            <strong>Concentration</strong>
            <span>:</span>
            <span className="property-value">Yes</span>
          </div>
        )}
        <div className="property-group">
          <strong>Classes</strong>
          <span>:</span>
          <span className="property-value">{capitalizeClasses(spell.classes)}</span>
        </div>
        {spell.components.material && spell.components.materials_needed && (
          <div className="materials">
            <MaterialIcon />
            <span className="material-description">
              {convertIfNeeded(spell.components.materials_needed.join(', '))}
            </span>
          </div>
        )}
      </div>

      <div className="spell-content__description">
        {formatSpellText(spell.description).map((paragraph, index) => (
          <p 
            key={index}
            dangerouslySetInnerHTML={{ 
              __html: convertIfNeeded(paragraph) 
            }}
          />
        ))}
      </div>

      {spell.cantrip_upgrade && (
        <div className="cantrip-upgrade">
          <h3>Cantrip Upgrade</h3>
          <p 
            dangerouslySetInnerHTML={{ 
              __html: convertIfNeeded(formatSpellText(spell.cantrip_upgrade)[0]) 
            }} 
          />
        </div>
      )}

      {spell.higher_levels && (
        <div className="higher-levels">
          <h3>At Higher Levels</h3>
          <p 
            dangerouslySetInnerHTML={{ 
              __html: convertIfNeeded(formatSpellText(spell.higher_levels)[0]) 
            }} 
          />
        </div>
      )}

      {spell.sources && (
        <div className="spell-source">
          <p>
            Found on p.{spell.sources[0].page} of {spell.sources[0].book}
            {spell.sources.length > 1 && spell.sources.slice(1).map((source, index) => (
              <span key={index}>
                {index === spell.sources.length - 2 ? ', and ' : ', '}
                on p.{source.page} of {source.book}
              </span>
            ))}
            {spell.srd ? ' (Free Rules)' : ''}
          </p>
        </div>
      )}
    </div>
  );
};

export default SpellContent; 