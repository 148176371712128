import React, { useEffect, useRef, useState } from 'react';
import '../styles/components/SpellInfoBox.css';
import SpellContent from './SpellContent';
import { auth } from '../firebase/firebase';
import { getUserSpellNotes } from '../firebase/firestoreOperations';

const SpellInfoBox = ({ spell, onClose, convertToMetric }) => {
  const [notes, setNotes] = useState('');
  const boxRef = useRef(null);

  useEffect(() => {
    const loadNotes = async () => {
      if (auth.currentUser && spell) {
        const userNotes = await getUserSpellNotes(auth.currentUser.uid, spell.id);
        setNotes(userNotes);
      }
    };
    loadNotes();
  }, [spell]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="spell-info-box-overlay">
      <div className="spell-info-box" ref={boxRef}>
        <button className="spell-info-box__close-button" onClick={onClose}>&times;</button>
        <SpellContent spell={spell} convertToMetric={convertToMetric} />
        
        {notes && (
          <div className="spell-info-box__notes">
            <h3>Personal Notes</h3>
            <div className="spell-info-box__notes-content">
              {notes}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SpellInfoBox;
