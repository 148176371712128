import React from 'react';
import { useParams, Link } from 'react-router-dom';
import spellsData from '../data/spells.json';
import '../styles/pages/SpellDetailPage.css';
import SpellContent from '../components/SpellContent';
import SpellNotes from '../components/SpellNotes';

function SpellDetailPage({ convertToMetric, setConvertToMetric }) {
  const { id } = useParams();
  const decodedSpellName = decodeURIComponent(id);
  const spell = spellsData.find(s => s.name === decodedSpellName);

  if (!spell) {
    return <div>Spell not found</div>;
  }

  const handleConvertUnitsChange = (event) => {
    setConvertToMetric(event.target.checked);
  };

  return (
    <div className="spell-detail-page">
      <div className="spell-detail-page__conversion">
        <label>
          <input
            type="checkbox"
            checked={convertToMetric}
            onChange={handleConvertUnitsChange}
          />
          Convert to Metric
        </label>
      </div>

      <SpellContent spell={spell} convertToMetric={convertToMetric} />
      
      <SpellNotes spellId={spell.id} />

      <Link to="/spells" className="back-link">
        <span style={{ marginRight: '8px' }}>←</span>
        Back to Spell List
      </Link>
    </div>
  );
}

export default SpellDetailPage;
