import React, { useState } from 'react';
import '../styles/components/HowToUseBox.css';
import { 
  NewInfoIcon, 
  AddToSpellbookIcon, 
  RemoveSpellIcon, 
  AllSpellsIcon, 
  TrashcanIcon,
  CastSpellIcon 
} from '../data/Icons';

function HowToUseBox({ pageType }) {
  const [isOpen, setIsOpen] = useState(false);

  const getInstructions = () => {
    switch (pageType) {
      case 'spellList':
        return {
          title: "How to Use the Spell List",
          instructions: [
            "Browse through the spells and quickly see their properties in the table",
            "Use filters and search to find specific spells",
            <>Click on spell names to view detailed information and add personal notes, or use <span className="icon-inline"><NewInfoIcon /></span> for quick spell details</>,
            "Click inside a spell row to expand and show its short description, or use 'Show Short Descriptions' to expand all spells at once",
            <>Add spells to your spellbooks using <span className="icon-inline"><AddToSpellbookIcon /></span>, or select multiple spells with checkboxes and use the 'Add selected to spellbook' button</>,
            "Toggle 'Convert to Metric' to see distances in metric units"
          ]
        };
      case 'spellBook':
        return {
          title: "How to Use Spellbooks",
          instructions: [
            "Create new spellbooks by entering a name in the field and clicking 'Create'. The spellbook appears as a tab below",
            "Switch between spellbooks using the tabs. Drag and drop tabs to reorder them",
            "Add spells from the Spell List page to populate your spellbook",
            <>Remove spells using <span className="icon-inline"><RemoveSpellIcon /></span> or select multiple with checkboxes and use the 'Remove selected' button</>,
            <>Prepare spells for casting using <span className="icon-inline"><AllSpellsIcon /></span>. These spells will appear on the Prepared Spells page</>,
            <>Delete a spellbook by clicking <span className="icon-inline"><TrashcanIcon /></span>. You cannot delete the last spellbook!</>,
            "Use the Spellbook Generator to create themed spellbooks. Choose caster level and spell distribution",
            "Note: You don't need a spellbook-using character to use this feature. Create spellbooks for any spellcasting class!"
          ]
        };
      case 'preparedSpells':
        return {
          title: "How to Use Prepared Spells",
          instructions: [
            "Choose your Active Spellbook from the dropdown. Each spellbook has its own prepared spells and spell slots",
            "The table shows your prepared spells. Enable 'Show Ritual Spells' to also see available ritual spells at the bottom",
            <>Cast spells using <span className="icon-inline"><CastSpellIcon /></span>. This automatically tracks your spell slots</>,
            <>Unprepare spells using <span className="icon-inline"><AllSpellsIcon /></span> to remove them from the list</>,
            "Manage your spell slots using the slot tracker. Set maximum slots per level and track usage",
            "Use the 'Reset All' button after a long rest to clear all used spell slots",
            "Create Spell Presets to save and quickly load different prepared spell combinations for various situations (cities, dungeons, etc.)"
          ]
        };
      default:
        return {
          title: "How to Use",
          instructions: ["No specific instructions available for this page."]
        };
    }
  };

  const { title, instructions } = getInstructions();

  return (
    <div className={`how-to-use-box ${isOpen ? 'active' : ''}`}>
      <div className="how-to-use-question" onClick={() => setIsOpen(!isOpen)}>
        <h3>{title}</h3>
        <span className="how-to-use-icon"></span>
      </div>
      <div className="how-to-use-answer">
        <ul>
          {instructions.map((instruction, index) => (
            <li key={index}>{instruction}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default HowToUseBox; 