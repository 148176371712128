import React, { useRef, useEffect } from 'react';
import '../styles/components/AddToSpellbookModal.css';

function AddToSpellbookModal({ isOpen, onClose, onSubmit, spellbooks, selectedSpells }) {
  const modalRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const handleSpellbookSelect = (event) => {
    const selectedSpellbook = event.target.value;
    if (selectedSpellbook) {
      onSubmit(selectedSpellbook);
      onClose();
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content" ref={modalRef}>
        <div className="modal-header">
          <h2>Add to Spellbook</h2>
          <button className="close-button" onClick={onClose}>&times;</button>
        </div>
        <div className="modal-body">
          <p>Adding {selectedSpells.length} spell(s) to spellbook:</p>
          <select 
            onChange={handleSpellbookSelect}
            defaultValue=""
          >
            <option value="" disabled>Select a spellbook</option>
            {Object.keys(spellbooks).map(book => (
              <option key={book} value={book}>{book}</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}

export default AddToSpellbookModal;
