import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react';
import { useTable, useSortBy } from 'react-table';
import { Link } from 'react-router-dom';
import spellsData from '../data/spells.json';
import '../styles/components/SpellList.css';
import SearchBar from './SearchBar';
import useSpellFilters from '../hooks/useSpellFilters';
import useSortSpells from '../hooks/useSortSpells';
import useSearchSpells from '../hooks/useSearchSpells';
import useCheckboxSelection from '../hooks/useCheckboxSelection';
import useUnitConversion from '../hooks/useUnitConversion';
import { formatUnits } from '../utils/formatUnits';
import AddToSpellbookModal from './AddToSpellbookModal';
import NotificationPopup from './NotificationPopup';
import { AddToSpellbookIcon, NewInfoIcon } from '../data/Icons';
import { BackToTopIcon } from '../data/Icons';
import SpellInfoBox from './SpellInfoBox';
import SpellCounter from './SpellCounter';
import { NotificationContext } from '../App';
import { convertUnits } from '../utils/converUnits'; // Make sure this import is present
import { debounce } from 'lodash'; // Make sure to install lodash if not already present
import SpellTable from './SpellTable';
import HowToUseBox from './HowToUseBox';
import SpellFilters from './SpellFilters';

const SpellList = React.memo(({ 
  filters, 
  setFilters, 
  searchTerm, 
  setSearchTerm, 
  convertToMetric,
  setConvertToMetric,
  spellbooks, 
  addToSpellbook, 
  showShortDescriptions,
  setShowShortDescriptions,
  sortConfig,
  setSortConfig,
  pagination,
  setPagination
}) => {
  const [spells, setSpells] = useState([]);
  const [localSelectedSpells, setLocalSelectedSpells] = useState({});
  const [isAnySpellSelected, setIsAnySpellSelected] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [spellsToAdd, setSpellsToAdd] = useState([]);
  const [notification, setNotification] = useState(null);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [selectedSpell, setSelectedSpell] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});
  const [visibleColumns, setVisibleColumns] = useState(10);

  const { addNotification } = useContext(NotificationContext);

  const memoizedData = useMemo(() => spells, [spells]);
  const memoizedColumns = useMemo(() => [
    // Define your columns here
  ], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
    },
    useSortBy
  );

  const {
    filteredSpells,
    uniqueLevels,
    uniqueSchools,
    uniqueClasses,
    componentOptions,
    uniqueSources,
    handleFilterChange,
    handleRemoveFilter,
    handleClearFilters,
    getActiveFilters
  } = useSpellFilters(spells, filters, setFilters);

  const { sortedSpells, requestSort } = useSortSpells(filteredSpells, sortConfig, setSortConfig);
  const searchedSpells = useSearchSpells(sortedSpells, searchTerm);
  const { toggleItem, selectAll, areAllSelected, clearSelection } = useCheckboxSelection(searchedSpells, localSelectedSpells, setLocalSelectedSpells);
  const { toggleConversion, convertIfNeeded } = useUnitConversion(convertToMetric, setConvertToMetric);

  useEffect(() => {
    setSpells(spellsData);
    setIsAnySpellSelected(Object.values(localSelectedSpells).some(value => value));
  }, [localSelectedSpells]);

  useEffect(() => {
    localStorage.setItem('showShortDescriptions', JSON.stringify(showShortDescriptions));
    // Reset expanded rows when global checkbox changes
    setExpandedRows({});
  }, [showShortDescriptions]);

  const getSourceAbbreviation = (source) => {
    if (!source || typeof source !== 'string') return '';
    const abbreviations = {
      'Elemental Evil': 'EE',
      "Player's Handbook": 'PHB',
      "Sword Coast Adventurer's Guide": 'SCAG',
      "Xanathar's Guide to Everything": 'XGtE',
      "2024 Player's Handbook 5.0": 'PHB 2024'
    };
    return abbreviations[source] || source;
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getSpellLevel = (level) => {
    if (level === 'cantrip') return 'Cantrip';
    return level.toString();
  };

  const getComponents = (spell) => {
    const components = [];
    if (spell.components.verbal) components.push('V');
    if (spell.components.somatic) components.push('S');
    if (spell.components.material) components.push('M');
    return components.join(', ');
  };

  const formatTableValue = (value) => {
    if (typeof value === 'string') {
      if (value.startsWith('1 reaction')) {
        return '1 reaction';
      }
      if (value.startsWith('Concentration,')) {
        return value.replace('Concentration, ', '');
      }
    }
    return formatUnits(convertIfNeeded(value));
  };

  // Add this new function to handle short description conversion
  const formatShortDescription = (description) => {
    return convertToMetric ? convertUnits(description) : description;
  };

  const handleAddToSpellbook = (spell) => {
    setSpellsToAdd([spell.name]);
    setIsModalOpen(true);
    // Remove the notification from here
  };

  const handleAddSelectedToSpellbook = () => {
    const selectedSpellsList = Object.keys(localSelectedSpells).filter(spell => localSelectedSpells[spell]);
    setSpellsToAdd(selectedSpellsList);
    setIsModalOpen(true);
  };

  const handleModalSubmit = useCallback((spellbookName) => {
    const existingSpells = spellbooks[spellbookName] || [];
    const newSpells = [];
    const alreadyExistingSpells = [];

    spellsToAdd.forEach(spell => {
      if (existingSpells.some(existingSpell => existingSpell.name === spell)) {
        alreadyExistingSpells.push(spell);
      } else {
        newSpells.push(spell);
      }
    });

    addToSpellbook(spellbookName, newSpells);
    
    setLocalSelectedSpells({}); // Clear local selection
    
    // Set notification based on the number of spells added and already existing
    if (newSpells.length === 1 && alreadyExistingSpells.length === 0) {
      addNotification(`${newSpells[0]} has been added to ${spellbookName}`, 'success');
    } else if (newSpells.length === 0 && alreadyExistingSpells.length === 1) {
      addNotification(`${alreadyExistingSpells[0]} is already in ${spellbookName}`, 'warning');
    } else {
      const addedMessage = newSpells.length > 0 ? `${newSpells.length} spell${newSpells.length !== 1 ? 's' : ''} added to ${spellbookName}` : '';
      const existingMessage = alreadyExistingSpells.length > 0 ? `${alreadyExistingSpells.length} spell${alreadyExistingSpells.length !== 1 ? 's were' : ' was'} already in ${spellbookName}` : '';
      
      if (addedMessage && existingMessage) {
        addNotification(`${addedMessage}, ${existingMessage}`, 'info');
      } else {
        addNotification(addedMessage || existingMessage, 'info');
      }
    }
    
    // Close modal and clear spellsToAdd
    setIsModalOpen(false);
    setSpellsToAdd([]);
  }, [addNotification, addToSpellbook, spellsToAdd, spellbooks]);

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSpellsToAdd([]);
  };

  const handleScroll = useCallback(() => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setShowBackToTop(scrollTop > 300);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleSpellInfoClick = (spell) => {
    setSelectedSpell(spell);
  };

  const toggleSpellDescription = useCallback((spellName, event) => {
    // Prevent toggling if the click was on a checkbox or icon
    if (event.target.type === 'checkbox' || event.target.closest('.info-icon, .add-to-spellbook-icon')) {
      return;
    }
    setExpandedRows(prev => {
      const newState = { ...prev };
      newState[spellName] = !prev[spellName];
      return newState;
    });
  }, []);

  const isRowExpanded = useCallback((spellName) => {
    return showShortDescriptions || expandedRows[spellName];
  }, [showShortDescriptions, expandedRows]);

  const handleConvertToMetricChange = (event) => {
    setConvertToMetric(event.target.checked);
  };

  const updateVisibleColumns = useCallback(debounce(() => {
    const width = window.innerWidth;
    let count = 10;

    if (width <= 600) count = 3;
    else if (width <= 700) count = 4;
    else if (width <= 800) count = 5;
    else if (width <= 900) count = 6;
    else if (width <= 1000) count = 7;
    else if (width <= 1100) count = 8;
    else if (width <= 1200) count = 9;

    setVisibleColumns(count);
  }, 200), []);

  useEffect(() => {
    updateVisibleColumns();
    window.addEventListener('resize', updateVisibleColumns);
    return () => {
      window.removeEventListener('resize', updateVisibleColumns);
      updateVisibleColumns.cancel();
    };
  }, [updateVisibleColumns]);

  const renderIcons = (spell) => {
    return (
      <>
        <span 
          className="info-icon"
          onClick={(e) => {
            e.stopPropagation();
            handleSpellInfoClick(spell);
          }}
          title="Spell Info"
        >
          <NewInfoIcon />
        </span>
        <span 
          className="add-to-spellbook-icon"
          onClick={(e) => {
            e.stopPropagation();
            handleAddToSpellbook(spell);
          }}
          title="Add to Spellbook"
        >
          <AddToSpellbookIcon />
        </span>
      </>
    );
  };

  return (
    <div className="spell-list">
      <HowToUseBox pageType="spellList" />
      <div className="search-and-conversion">
        <div className="search-and-count">
          <SearchBar searchTerm={searchTerm} onSearchChange={setSearchTerm} />
          <SpellCounter count={searchedSpells.length} />
        </div>
        <div className="checkbox-container">
          <label>
            <input
              type="checkbox"
              checked={convertToMetric}
              onChange={handleConvertToMetricChange}
            />
            Convert to Metric
          </label>
          <label>
            <input
              type="checkbox"
              checked={showShortDescriptions}
              onChange={(e) => setShowShortDescriptions(e.target.checked)}
            />
            Show Short Descriptions
          </label>
        </div>
      </div>
      <SpellFilters
        filters={filters}
        setFilters={setFilters}
        filterOptions={{
          classes: uniqueClasses,
          levels: uniqueLevels,
          schools: uniqueSchools,
          components: componentOptions,
          sources: uniqueSources
        }}
      />
      <SpellTable
        spells={searchedSpells}
        selectedSpells={localSelectedSpells}
        toggleItem={toggleItem}
        selectAll={selectAll}
        areAllSelected={areAllSelected}
        showShortDescriptions={showShortDescriptions}
        convertToMetric={convertToMetric}
        expandedRows={expandedRows}
        setExpandedRows={setExpandedRows}
        visibleColumns={visibleColumns}
        renderIcons={renderIcons}
        sortConfig={sortConfig}
        setSortConfig={setSortConfig}
        pagination={pagination}
        setPagination={setPagination}
        tabName="spellList"
      />

      {isAnySpellSelected && !isModalOpen && (
        <button 
          onClick={handleAddSelectedToSpellbook} 
          className="floating-add-button"
        >
          Add Selected to Spellbook
        </button>
      )}

      <AddToSpellbookModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSubmit={handleModalSubmit}
        spellbooks={spellbooks || {}} // Provide a default empty object if spellbooks is undefined
        selectedSpells={spellsToAdd}
      />

      {notification && (
        <NotificationPopup
          message={notification}
          onClose={() => setNotification(null)}
        />
      )}

      {showBackToTop && (
        <button className="back-to-top" onClick={scrollToTop}>
          <BackToTopIcon />
        </button>
      )}

      {selectedSpell && (
        <>
          <div className="blur-overlay"></div>
          <SpellInfoBox
            spell={selectedSpell}
            onClose={() => setSelectedSpell(null)}
            convertToMetric={convertToMetric}
          />
        </>
      )}
    </div>
  );
});

export default SpellList;
