import { useMemo } from 'react';
import { sortSpells, updateSortConfig } from '../utils/sortSpells';

function useSortSpells(spells, sortConfig, setSortConfig) {
  const defaultConfig = { key: 'name', direction: 'asc' };
  
  const sortedSpells = useMemo(() => {
    return sortSpells(spells, sortConfig || defaultConfig);
  }, [spells, sortConfig]);

  const requestSort = (key) => {
    const newSortConfig = updateSortConfig(sortConfig || defaultConfig, key);
    setSortConfig(newSortConfig);
  };

  return { sortedSpells, requestSort, sortConfig: sortConfig || defaultConfig };
}

export default useSortSpells;