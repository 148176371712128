import spellsData from '../data/spells.json';

// Add this constant at the top of the file
export const WIZARD_SUBCLASSES = {
  'Abjurer': 'abjuration',
  'Conjurer': 'conjuration',
  'Diviner': 'divination',
  'Enchanter': 'enchantment',
  'Evoker': 'evocation',
  'Illusionist': 'illusion',
  'Necromancer': 'necromancy',
  'Transmuter': 'transmutation'
};

/**
 * Filter spells based on given criteria
 * @param {Object} criteria - Filtering criteria
 * @param {string[]} [criteria.classes] - Array of class names
 * @param {number[]} [criteria.levels] - Array of spell levels
 * @param {string[]} [criteria.schools] - Array of magic schools
 * @param {boolean} [criteria.ritualOnly] - Include only ritual spells
 * @param {string[]} [criteria.sources] - Array of source books
 * @param {boolean} [criteria.phb2024Only] - Include only PHB 2024 spells
 * @param {string} [criteria.subclass] - Wizard subclass school
 * @returns {Array} Filtered array of spells
 */
export const filterSpellsForGeneration = (criteria) => {
  return spellsData.filter(spell => {
    // Check if PHB 2024 only
    if (criteria.phb2024Only) {
      // Check if spell has sources and at least one source is PHB 2024
      const has2024Source = spell.sources?.some(source => 
        source.book === "2024 Player's Handbook 5.0"
      );
      if (!has2024Source) {
        return false;
      }
    }

    // Check classes
    if (criteria.classes?.length > 0) {
      if (!spell.classes.some(spellClass => 
        criteria.classes.includes(spellClass.toLowerCase())
      )) {
        return false;
      }
    }

    // Check levels
    if (criteria.levels?.length > 0) {
      // Convert spell level to number for comparison
      const spellLevel = spell.level === 'cantrip' ? 0 : parseInt(spell.level);
      if (!criteria.levels.includes(spellLevel)) {
        return false;
      }
    }

    // Check schools
    if (criteria.schools?.length > 0) {
      if (!criteria.schools.includes(spell.school)) {
        return false;
      }
    }

    // Check ritual
    if (criteria.ritualOnly && !spell.ritual) {
      return false;
    }

    // Check sources
    if (criteria.sources?.length > 0) {
      if (!spell.sources?.some(source => criteria.sources.includes(source.book))) {
        return false;
      }
    }

    return true;
  });
};

/**
 * Generate a random spellbook based on criteria
 * @param {Object} options - Generation options
 * @param {Object} options.criteria - Filtering criteria
 * @param {number} options.spellCount - Number of spells to include
 * @param {boolean} [options.allowDuplicates=false] - Allow duplicate spells
 * @param {string} [options.subclass] - Wizard subclass school
 * @returns {Array} Array of selected spells
 */
export const generateSpellbook = ({ criteria, spellCount, allowDuplicates = false, subclass = null }) => {
  const eligibleSpells = filterSpellsForGeneration(criteria);
  const selectedSpells = [];
  
  if (eligibleSpells.length === 0) {
    console.log('No eligible spells found for criteria:', criteria);
    return [];
  }

  // If subclass is specified, first select school-specific spells
  if (subclass) {
    const schoolSpells = eligibleSpells.filter(spell => spell.school.toLowerCase() === subclass.toLowerCase());
    
    // Determine how many school-specific spells we want
    let requiredSchoolSpells = 1; // Default to 1 for 2-3 spells
    if (spellCount >= 4) {
      requiredSchoolSpells = 2;
    }

    // Select school-specific spells
    const schoolSpellsToAdd = Math.min(requiredSchoolSpells, schoolSpells.length);
    for (let i = 0; i < schoolSpellsToAdd; i++) {
      const randomIndex = Math.floor(Math.random() * schoolSpells.length);
      const selectedSpell = schoolSpells[randomIndex];
      selectedSpells.push(selectedSpell);
      
      if (!allowDuplicates) {
        // Remove selected spell from both arrays
        schoolSpells.splice(randomIndex, 1);
        const mainIndex = eligibleSpells.findIndex(spell => spell.name === selectedSpell.name);
        if (mainIndex !== -1) eligibleSpells.splice(mainIndex, 1);
      }
    }
  }

  // Fill remaining slots with random spells (which might include more school spells)
  const remainingCount = spellCount - selectedSpells.length;
  for (let i = 0; i < remainingCount; i++) {
    if (eligibleSpells.length === 0) break;
    
    const randomIndex = Math.floor(Math.random() * eligibleSpells.length);
    const selectedSpell = eligibleSpells[randomIndex];

    if (!allowDuplicates) {
      eligibleSpells.splice(randomIndex, 1);
    }
    
    selectedSpells.push(selectedSpell);
  }

  return selectedSpells;
};

/**
 * Get unique values for each spell property
 * @returns {Object} Object containing arrays of unique values for each property
 */
export const getSpellbookGeneratorOptions = () => {
  const options = {
    classes: new Set(),
    levels: new Set(),
    schools: new Set(),
    sources: new Set()
  };

  spellsData.forEach(spell => {
    spell.classes.forEach(className => options.classes.add(className.toLowerCase()));
    // Convert spell level to number
    const level = spell.level === 'cantrip' ? 0 : parseInt(spell.level);
    options.levels.add(level);
    options.schools.add(spell.school);
    options.sources.add(spell.source);
  });

  return {
    classes: Array.from(options.classes).sort(),
    levels: Array.from(options.levels).sort((a, b) => a - b),
    schools: Array.from(options.schools).sort(),
    sources: Array.from(options.sources).sort()
  };
}; 