import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { logout } from '../firebase/authFunctions';
import '../styles/components/NavBar.css';
import '../styles/components/NavBarMobile.css';
import logoImage from '../assets/images/nature.png';
import { AccountIcon } from '../data/Icons';

function NavBar() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMenuOpen(false);
    };

    let resizeTimer;
    window.addEventListener('resize', () => {
      clearTimeout(resizeTimer);
      
      document.body.classList.add('resize-animation-stopper');
      
      resizeTimer = setTimeout(() => {
        document.body.classList.remove('resize-animation-stopper');
      }, 400);

      handleResize();
    });

    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(resizeTimer);
    };
  }, []);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error("Failed to log out", error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="main-nav">
      <div className="wrapper">
        <div className="logo">
          <img src={logoImage} alt="Logo" className="nav-logo" />
          <Link to="/">TableMancer</Link>
        </div>
        <div className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
          <button className="close-menu-btn" onClick={toggleMenu}>
            <span className="close-icon">×</span>
          </button>
          <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
          <li>
            <input type="checkbox" id="showSpells" />
            <label htmlFor="showSpells" className="mobile-item">Spells</label>
            <a href="#" className="desktop-item">Spells</a>
            <ul className="drop-menu">
              <li><Link to="/spells" onClick={toggleMenu}>Spell List</Link></li>
              <li><Link to="/spellbooks" onClick={toggleMenu}>Spellbooks</Link></li>
              <li><Link to="/prepared-spells" onClick={toggleMenu}>Prepared Spells</Link></li>
            </ul>
          </li>
          <li>
            <input type="checkbox" id="showTools" />
            <label htmlFor="showTools" className="mobile-item">Tools</label>
            <a href="#" className="desktop-item">Tools</a>
            <ul className="drop-menu">
              <li><Link to="/tools/dice-roller" onClick={toggleMenu}>Dice Roller</Link></li>
              <li><Link to="/tools/character-generator" onClick={toggleMenu}>Character Generator</Link></li>
              <li><Link to="/tools/encounter-builder" onClick={toggleMenu}>Encounter Builder</Link></li>
            </ul>
          </li>
          <li>
            <input type="checkbox" id="showMore" />
            <label htmlFor="showMore" className="mobile-item">More</label>
            <a href="#" className="desktop-item">More</a>
            <ul className="drop-menu">
              <li><Link to="/how-to" onClick={toggleMenu}>How To Use</Link></li>
              <li><Link to="/faq" onClick={toggleMenu}>FAQ</Link></li>
              <li><Link to="/about" onClick={toggleMenu}>About</Link></li>
              <li><Link to="/contact" onClick={toggleMenu}>Contact</Link></li>
            </ul>
          </li>
          {currentUser ? (
            <li className="account-item">
              <input type="checkbox" id="showAccount" />
              <label htmlFor="showAccount" className="mobile-item">
                <AccountIcon />
              </label>
              <a href="#" className="desktop-item">
                <AccountIcon />
              </a>
              <ul className="drop-menu">
                <li><Link to="/account" onClick={toggleMenu}>Account</Link></li>
                <li><button onClick={handleLogout}>Log Out</button></li>
              </ul>
            </li>
          ) : (
            <li className="login-item">
              <Link to="/login" onClick={toggleMenu}>Log In / Register</Link>
            </li>
          )}
        </div>
        <div className="hamburger" onClick={toggleMenu}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
