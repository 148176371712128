import React, { useMemo, useState, useEffect, useCallback, useContext } from 'react';
import SearchBar from './SearchBar';
import useSpellFilters from '../hooks/useSpellFilters';
import useSortSpells from '../hooks/useSortSpells';
import useSearchSpells from '../hooks/useSearchSpells';
import useCheckboxSelection from '../hooks/useCheckboxSelection';
import useUnitConversion from '../hooks/useUnitConversion';
import { AllSpellsIcon, BackToTopIcon, NewInfoIcon, CastSpellIcon } from '../data/Icons';
import '../styles/components/PreparedSpells.css';
import NotificationPopup from './NotificationPopup';
import SpellInfoBox from './SpellInfoBox';
import SpellCounter from './SpellCounter';
import { NotificationContext } from '../App';
import { useAuth } from '../AuthContext';
import { getUserPresets, updateUserPresets, updateUserPreparedSpells, getUserSettings, updateUserSettings } from '../firebase/firestoreOperations';
import PreparedSpellsPresets from './PreparedSpellsPresets';
import spellsData from '../data/spells.json';
import SpellSlotManager from './SpellSlotManager';
import ConfirmationPopup from './ConfirmationPopup';
import { debounce } from 'lodash';
import SpellTable from './SpellTable';
import HowToUseBox from './HowToUseBox';
import SpellFilters from './SpellFilters';

function PreparedSpells({
  filters,
  setFilters,
  selectedSpells,
  setSelectedSpells,
  searchTerm,
  setSearchTerm,
  convertToMetric,
  setConvertToMetric,
  spellbooks,
  showRitualSpells,
  setShowRitualSpells,
  showShortDescriptions,
  setShowShortDescriptions,
  spellSlots,
  setSpellSlots,
  currentSpellbook,
  setCurrentSpellbook,
  preparedSpellsByBook,
  updatePreparedSpells,
  spellSlotsByBook,
  updateSpellSlots,
  sortConfig,
  setSortConfig,
  pagination,
  setPagination
}) {
  const { addNotification } = useContext(NotificationContext);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [selectedSpell, setSelectedSpell] = useState(null);
  const [expandedRows, setExpandedRows] = useState({}); // Add this line
  const { currentUser } = useAuth();
  const [presets, setPresets] = useState({});
  const [currentPreset, setCurrentPreset] = useState(null);
  const [confirmationPopup, setConfirmationPopup] = useState({ isOpen: false, message: '', onConfirm: null });
  const [visibleColumns, setVisibleColumns] = useState(10);

  const updateVisibleColumns = useCallback(debounce(() => {
    const width = window.innerWidth;
    let count = 10;

    if (width <= 600) count = 3;
    else if (width <= 700) count = 4;
    else if (width <= 800) count = 5;
    else if (width <= 900) count = 6;
    else if (width <= 1000) count = 7;
    else if (width <= 1100) count = 8;
    else if (width <= 1200) count = 9;

    setVisibleColumns(count);
  }, 200), []);

  useEffect(() => {
    updateVisibleColumns();
    window.addEventListener('resize', updateVisibleColumns);
    return () => {
      window.removeEventListener('resize', updateVisibleColumns);
      updateVisibleColumns.cancel();
    };
  }, [updateVisibleColumns]);

  const allSpells = useMemo(() => {
    if (!spellbooks[currentSpellbook]) return [];
    
    // Create a Map to store unique spells
    const uniqueSpells = new Map();
    
    // Only use spells from the current spellbook
    spellbooks[currentSpellbook].forEach(spell => {
      // If the spell is not in the Map or if it's in preparedSpells, add/update it
      if (!uniqueSpells.has(spell.name) || preparedSpellsByBook.some(ps => ps.name === spell.name)) {
        uniqueSpells.set(spell.name, spell);
      }
    });
    
    // Convert the Map values back to an array
    return Array.from(uniqueSpells.values());
  }, [spellbooks, currentSpellbook, preparedSpellsByBook]);

  const {
    filteredSpells,
    uniqueLevels,
    uniqueSchools,
    uniqueClasses,
    componentOptions,
    uniqueSources,
    handleFilterChange,
    handleRemoveFilter,
    handleClearFilters,
    getActiveFilters
  } = useSpellFilters(allSpells, filters, setFilters);

  const { sortedSpells, requestSort } = useSortSpells(filteredSpells, sortConfig, setSortConfig);
  const searchedSpells = useSearchSpells(sortedSpells, searchTerm);
  const { toggleItem, selectAll, areAllSelected, clearSelection } = useCheckboxSelection(searchedSpells, selectedSpells, setSelectedSpells);
  const { toggleConversion, convertIfNeeded } = useUnitConversion(convertToMetric, setConvertToMetric);

  const spellsToDisplay = useMemo(() => {
    const currentBookPreparedSpells = preparedSpellsByBook[currentSpellbook] || [];
    const preparedSpellsSet = new Set(currentBookPreparedSpells.map(spell => spell.name));
    const filteredPreparedSpells = searchedSpells.filter(spell => preparedSpellsSet.has(spell.name));
    
    if (showRitualSpells) {
      const ritualSpells = searchedSpells.filter(spell => 
        spell.ritual && !preparedSpellsSet.has(spell.name)
      ).map(spell => ({
        ...spell,
        isPrepared: false
      }));
      
      return [
        ...filteredPreparedSpells.map(spell => ({
          ...spell,
          isPrepared: true
        })),
        { id: 'separator', isSeparator: true },
        ...ritualSpells
      ];
    }
    return filteredPreparedSpells.map(spell => ({
      ...spell,
      isPrepared: true
    }));
  }, [showRitualSpells, preparedSpellsByBook, currentSpellbook, searchedSpells]);

  const renderIcons = (spell) => {
    if (spell.isSeparator) return null;
    const isPrepared = isSpellPrepared(spell.name);
    const isRitual = !isPrepared && spell.ritual;

    return (
      <div className="spell-icons" data-spell-name={spell.name}>
        {spell.level > 0 && isPrepared && (
          <span 
            className="cast-spell-icon"
            onClick={(e) => {
              e.stopPropagation();
              handleCastSpell(spell);
            }}
            title="Cast Spell"
          >
            <CastSpellIcon />
          </span>
        )}
        <span 
          className="info-icon"
          onClick={(e) => {
            e.stopPropagation();
            handleSpellInfoClick(spell);
          }}
          title="Spell Info"
        >
          <NewInfoIcon />
        </span>
        <span 
          className={`prepare-spell-icon ${isPrepared ? 'prepared' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            isPrepared ? handleUnprepareSpell(spell.name) : handlePrepareSpell(spell);
          }}
          title={isPrepared ? "Unprepare Spell" : "Prepare Spell"}
        >
          <AllSpellsIcon />
        </span>
      </div>
    );
  };

  const getSourceAbbreviation = (source) => {
    const abbreviations = {
      'Elemental Evil': 'EE',
      "Player's Handbook": 'PHB',
      "Sword Coast Adventurer's Guide": 'SCAG',
      "Xanathar's Guide to Everything": 'XGtE'
    };
    return abbreviations[source] || source;
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getSpellLevel = (spell) => {
    if (spell.isSeparator) return '';
    const level = spell.level;
    return level === 0 ? 'Cantrip' : level.toString();
  };

  const getComponents = (spell) => {
    const components = [];
    if (spell.components.verbal) components.push('V');
    if (spell.components.somatic) components.push('S');
    if (spell.components.material) components.push('M');
    return components.join(', ');
  };

  const handleUnprepareSelectedSpells = () => {
    const selectedSpellsList = Object.keys(selectedSpells).filter(spell => selectedSpells[spell]);
    const spellsToUnprepare = spellsToDisplay
      .filter(spell => !spell.isSeparator && selectedSpellsList.includes(spell.name) && spell.isPrepared);

    if (spellsToUnprepare.length > 0) {
      const currentPreparedSpells = preparedSpellsByBook[currentSpellbook] || [];
      const newPreparedSpells = currentPreparedSpells.filter(spell => 
        !selectedSpellsList.includes(spell.name)
      );
      
      updatePreparedSpells(currentSpellbook, newPreparedSpells);
      clearSelection();
      addNotification(`${spellsToUnprepare.length} spell${spellsToUnprepare.length > 1 ? 's' : ''} unprepared`, 'info');
    }
  };

  const handlePrepareSelectedSpells = () => {
    const selectedSpellsList = Object.keys(selectedSpells).filter(spell => selectedSpells[spell]);
    const spellsToPrepare = spellsToDisplay
      .filter(spell => !spell.isSeparator && selectedSpellsList.includes(spell.name) && !spell.isPrepared);

    if (spellsToPrepare.length > 0) {
      const newPreparedSpells = [...preparedSpellsByBook[currentSpellbook], ...spellsToPrepare];
      updatePreparedSpells(currentSpellbook, newPreparedSpells);

      if (currentUser) {
        updateUserPreparedSpells(currentUser.uid, newPreparedSpells);
      } else {
        localStorage.setItem('guestPreparedSpells', JSON.stringify(newPreparedSpells.map(spell => spell.id)));
      }

      clearSelection();
      addNotification(`${spellsToPrepare.length} spell${spellsToPrepare.length > 1 ? 's' : ''} prepared`, 'success');
    }
  };

  const handleScroll = useCallback(() => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setShowBackToTop(scrollTop > 300);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    localStorage.setItem('showShortDescriptions', JSON.stringify(showShortDescriptions));
    setExpandedRows({}); // Reset expanded rows when showShortDescriptions changes
  }, [showShortDescriptions]);

  const toggleSpellDescription = useCallback((spellName, event) => {
    // Prevent toggling if the click was on a checkbox or icon
    if (event.target.type === 'checkbox' || event.target.closest('.info-icon, .prepare-spell-icon')) {
      return;
    }
    setExpandedRows(prev => ({
      ...prev,
      [spellName]: !prev[spellName]
    }));
  }, []);

  const isRowExpanded = useCallback((spellName) => {
    return showShortDescriptions || expandedRows[spellName];
  }, [showShortDescriptions, expandedRows]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleSpellInfoClick = (spell) => {
    setSelectedSpell(spell);
  };

  // Add this function:
  const handleConvertToMetricChange = (event) => {
    setConvertToMetric(event.target.checked);
  };

  useEffect(() => {
    if (currentUser) {
      const fetchUserPresets = async () => {
        const userPresets = await getUserPresets(currentUser.uid);
        setPresets(userPresets);
      };
      fetchUserPresets();
    } else {
      // Load presets from localStorage for non-logged-in users
      const guestPresets = JSON.parse(localStorage.getItem('guestPresets')) || {};
      setPresets(guestPresets);
    }
  }, [currentUser]);

  const savePreset = useCallback((spellbookName, presetName) => {
    const newPresets = {
      ...presets,
      [spellbookName]: {
        ...(presets[spellbookName] || {}),
        [presetName]: preparedSpellsByBook[currentSpellbook].map(spell => spell.id)
      }
    };
    setPresets(newPresets);
    setCurrentPreset(presetName);
    if (currentUser) {
      updateUserPresets(currentUser.uid, spellbookName, newPresets[spellbookName]);
    } else {
      localStorage.setItem('guestPresets', JSON.stringify(newPresets));
    }
    addNotification(`Preset "${presetName}" saved for ${spellbookName}`, 'success');
  }, [presets, preparedSpellsByBook, currentSpellbook, currentUser, addNotification]);

  const loadPreset = useCallback((spellbookName, presetName) => {
    if (presets[spellbookName]?.[presetName]) {
      const spellObjects = presets[spellbookName][presetName]
        .map(id => spellsData.find(spell => spell.id === id))
        .filter(Boolean);
      updatePreparedSpells(currentSpellbook, spellObjects);
      setCurrentPreset(presetName);
      addNotification(`Preset "${presetName}" loaded from ${spellbookName}`, 'info');
    }
  }, [presets, currentSpellbook, updatePreparedSpells, addNotification]);

  const deletePreset = useCallback((spellbookName, presetName) => {
    const newPresets = {
      ...presets,
      [spellbookName]: { ...(presets[spellbookName] || {}) }
    };
    delete newPresets[spellbookName][presetName];
    setPresets(newPresets);
    if (currentUser) {
      updateUserPresets(currentUser.uid, spellbookName, newPresets[spellbookName]);
    } else {
      localStorage.setItem('guestPresets', JSON.stringify(newPresets));
    }
    addNotification(`Preset "${presetName}" deleted from ${spellbookName}`, 'info');
  }, [presets, currentUser, addNotification]);

  const findNextAvailableSlot = (startLevel) => {
    for (let i = startLevel; i < currentSpellSlots.length; i++) {
      if (currentSpellSlots[i] && currentSpellSlots[i].used < currentSpellSlots[i].max) {
        return i;
      }
    }
    return -1;
  };

  const handleCastSpell = (spell) => {
    if (spell.level === 0) {
      addNotification(`${spell.name} cast successfully! (Cantrip)`, 'success');
      return;
    }

    const spellLevel = spell.level - 1;
    if (currentSpellSlots[spellLevel] && currentSpellSlots[spellLevel].used < currentSpellSlots[spellLevel].max) {
      // Find and animate the icon
      const iconContainer = document.querySelector(`[data-spell-name="${spell.name}"] .cast-spell-icon`);
      if (iconContainer) {
        iconContainer.classList.add('casting');
        setTimeout(() => iconContainer.classList.remove('casting'), 800); // Updated to match new animation duration
      }

      // Rest of the casting logic...
      const newSlots = [...currentSpellSlots];
      newSlots[spellLevel] = {
        ...newSlots[spellLevel],
        used: newSlots[spellLevel].used + 1
      };
      updateSpellSlots(currentSpellbook, newSlots);
      addNotification(`${spell.name} cast successfully!`, 'success');
    } else {
      // Try to find a higher level slot
      const nextAvailableSlotIndex = findNextAvailableSlot(spellLevel);
      if (nextAvailableSlotIndex !== -1) {
        setConfirmationPopup({
          isOpen: true,
          message: `Do you want to cast ${spell.name} using a level ${nextAvailableSlotIndex + 1} spell slot?`,
          onConfirm: () => {
            const iconContainer = document.querySelector(`[data-spell-name="${spell.name}"] .cast-spell-icon`);
            if (iconContainer) {
              iconContainer.classList.add('casting');
              setTimeout(() => iconContainer.classList.remove('casting'), 800); // Updated to match new animation duration
            }

            const newSlots = [...currentSpellSlots];
            newSlots[nextAvailableSlotIndex] = {
              ...newSlots[nextAvailableSlotIndex],
              used: newSlots[nextAvailableSlotIndex].used + 1
            };
            updateSpellSlots(currentSpellbook, newSlots);
            addNotification(`${spell.name} cast successfully at level ${nextAvailableSlotIndex + 1}!`, 'success');
            setConfirmationPopup({ isOpen: false, message: '', onConfirm: null });
          }
        });
      } else {
        addNotification(`No available spell slots to cast ${spell.name}.`, 'error');
      }
    }
  };

  const isAnySpellSelected = useMemo(() => {
    const selectedSpellsList = Object.keys(selectedSpells).filter(spell => selectedSpells[spell]);
    if (selectedSpellsList.length === 0) return false;

    const hasPreparableSpells = spellsToDisplay.some(spell => 
      !spell.isSeparator && 
      selectedSpells[spell.name] && 
      !spell.isPrepared
    );

    const hasUnpreparableSpells = spellsToDisplay.some(spell => 
      !spell.isSeparator && 
      selectedSpells[spell.name] && 
      spell.isPrepared
    );

    return { hasPreparableSpells, hasUnpreparableSpells };
  }, [selectedSpells, spellsToDisplay]);

  const getSpellCount = useCallback(() => {
    return spellsToDisplay.filter(spell => !spell.isSeparator).length;
  }, [spellsToDisplay]);

  const handleUnprepareSpell = (spellName) => {
    const currentPreparedSpells = preparedSpellsByBook[currentSpellbook] || [];
    const newPreparedSpells = currentPreparedSpells.filter(spell => spell.name !== spellName);
    updatePreparedSpells(currentSpellbook, newPreparedSpells);
    addNotification(`${spellName} has been unprepared from ${currentSpellbook}`, 'info');
  };

  const isSpellPrepared = useCallback((spellName) => {
    const currentPreparedSpells = preparedSpellsByBook[currentSpellbook] || [];
    return currentPreparedSpells.some(s => s.name === spellName);
  }, [preparedSpellsByBook, currentSpellbook]);

  const handlePrepareSpell = (spell) => {
    const currentPreparedSpells = preparedSpellsByBook[currentSpellbook] || [];
    
    if (isSpellPrepared(spell.name)) {
      // Unprepare the spell
      const newPreparedSpells = currentPreparedSpells.filter(s => s.name !== spell.name);
      updatePreparedSpells(currentSpellbook, newPreparedSpells);
      addNotification(`${spell.name} has been unprepared from ${currentSpellbook}`, 'info');
    } else {
      // Prepare the spell
      const newPreparedSpells = [...currentPreparedSpells, spell];
      updatePreparedSpells(currentSpellbook, newPreparedSpells);
      addNotification(`${spell.name} has been prepared in ${currentSpellbook}`, 'success');
    }
  };

  const handleSpellbookChange = (event) => {
    const newSpellbook = event.target.value;
    setCurrentSpellbook(newSpellbook);
    localStorage.setItem('lastActiveSpellbook', newSpellbook);
  };

  // Get the current spellbook's spell slots or create default ones
  const currentSpellSlots = useMemo(() => {
    return spellSlotsByBook[currentSpellbook] || Array(9).fill().map(() => ({ max: 0, used: 0 }));
  }, [spellSlotsByBook, currentSpellbook]);

  // Add this useEffect to load the ritual spells setting
  useEffect(() => {
    const loadRitualSpellsSetting = async () => {
      if (currentUser) {
        const settings = await getUserSettings(currentUser.uid);
        setShowRitualSpells(settings.showRitualSpells ?? true); // Default to true if not set
      } else {
        // For non-logged in users, use localStorage
        const savedSetting = localStorage.getItem('showRitualSpells');
        setShowRitualSpells(savedSetting === null ? true : savedSetting === 'true');
      }
    };

    loadRitualSpellsSetting();
  }, [currentUser, setShowRitualSpells]);

  // Add this function to handle the ritual spells checkbox change
  const handleRitualSpellsChange = async (e) => {
    const newValue = e.target.checked;
    setShowRitualSpells(newValue);
    
    if (currentUser) {
      // Update Firebase
      await updateUserSettings(currentUser.uid, {
        showRitualSpells: newValue
      });
    } else {
      // Update localStorage for non-logged in users
      localStorage.setItem('showRitualSpells', newValue);
    }
  };

  return (
    <div className="prepared-spells spell-list">
      <HowToUseBox pageType="preparedSpells" />
      <div className="active-spellbook-header">
        <h2>Active Spellbook:</h2>
        <select 
          value={currentSpellbook}
          onChange={handleSpellbookChange}
          className="spellbook-selector"
        >
          {Object.keys(spellbooks).map(book => (
            <option key={book} value={book}>
              {book}
            </option>
          ))}
        </select>
      </div>

      <SpellSlotManager 
        spellSlots={currentSpellSlots}
        setSpellSlots={(newSlots) => updateSpellSlots(currentSpellbook, newSlots)}
        spellbookName={currentSpellbook}
      />

      <div className="search-and-options">
        <div className="search-and-checkboxes">
          <div className="search-and-count">
            <SearchBar searchTerm={searchTerm} onSearchChange={setSearchTerm} />
            <SpellCounter count={getSpellCount()} /> {/* Update this line */}
          </div>
          <div className="options-checkboxes">
            <label>
              <input
                type="checkbox"
                checked={convertToMetric}
                onChange={handleConvertToMetricChange}
              />
              Convert to Metric
            </label>
            <label>
              <input
                type="checkbox"
                checked={showShortDescriptions}
                onChange={(e) => setShowShortDescriptions(e.target.checked)}
              />
              Show Short Descriptions
            </label>
            <label>
              <input
                type="checkbox"
                checked={showRitualSpells}
                onChange={handleRitualSpellsChange}
              />
              Show Ritual Spells
            </label>
          </div>
        </div>
      </div>

      <SpellFilters
        filters={filters}
        setFilters={setFilters}
        filterOptions={{
          classes: uniqueClasses,
          levels: uniqueLevels,
          schools: uniqueSchools,
          components: componentOptions,
          sources: uniqueSources
        }}
      />

      <SpellTable
        spells={spellsToDisplay}
        selectedSpells={selectedSpells}
        toggleItem={toggleItem}
        selectAll={selectAll}
        areAllSelected={areAllSelected}
        showShortDescriptions={showShortDescriptions}
        convertToMetric={convertToMetric}
        expandedRows={expandedRows}
        setExpandedRows={setExpandedRows}
        visibleColumns={visibleColumns}
        renderIcons={renderIcons}
        emptyMessage="No spells are currently prepared."
        showRitualStyling={showRitualSpells}
        sortConfig={sortConfig}
        setSortConfig={setSortConfig}
        pagination={pagination}
        setPagination={setPagination}
        tabName="preparedSpells"
      />

      {(isAnySpellSelected.hasPreparableSpells || isAnySpellSelected.hasUnpreparableSpells) && (
        <div className="floating-buttons">
          {isAnySpellSelected.hasPreparableSpells && (
            <button 
              onClick={handlePrepareSelectedSpells} 
              className="floating-button prepare-button"
            >
              Prepare Selected Spells
            </button>
          )}
          {isAnySpellSelected.hasUnpreparableSpells && (
            <button 
              onClick={handleUnprepareSelectedSpells} 
              className="floating-button unprepare-button"
            >
              Unprepare Selected Spells
            </button>
          )}
        </div>
      )}

      {showBackToTop && (
        <button className="back-to-top" onClick={scrollToTop}>
          <BackToTopIcon />
        </button>
      )}

      {selectedSpell && (
        <>
          <div className="blur-overlay"></div>
          <SpellInfoBox
            spell={selectedSpell}
            onClose={() => setSelectedSpell(null)}
            convertToMetric={convertToMetric}
          />
        </>
      )}

      <PreparedSpellsPresets
        presets={presets}
        setPresets={setPresets}
        onSavePreset={savePreset}
        onLoadPreset={loadPreset}
        onDeletePreset={deletePreset}
        availableSpells={allSpells}
        currentPreset={currentPreset}
        setCurrentPreset={setCurrentPreset}
        preparedSpells={preparedSpellsByBook[currentSpellbook]}
        currentSpellbook={currentSpellbook}
        currentUser={currentUser}
        addNotification={addNotification}
      />

      <ConfirmationPopup
        isOpen={confirmationPopup.isOpen}
        message={confirmationPopup.message}
        onConfirm={confirmationPopup.onConfirm}
        onCancel={() => setConfirmationPopup({ isOpen: false, message: '', onConfirm: null })}
      />
    </div>
  );
}

export default PreparedSpells;
