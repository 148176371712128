import React, { useState } from 'react';
import { useAuth } from '../AuthContext';
import PasswordChangeForm from '../components/PasswordChangeForm';
import EmailChangeForm from '../components/EmailChangeForm';
import { clearUserAccount } from '../firebase/firestoreOperations';
import '../styles/pages/AccountPage.css';

function AccountPage() {
  const { currentUser } = useAuth();
  const [activeTab, setActiveTab] = useState('account');
  const [showClearConfirmation, setShowClearConfirmation] = useState(false);

  if (!currentUser) {
    return (
      <div className="account-page">
        <div className="account-container">
          <h2>Account Access Denied</h2>
          <p>Please log in to view your account details.</p>
        </div>
      </div>
    );
  }

  // Format the creation date
  const creationDate = new Date(currentUser.metadata.creationTime);
  const formattedDate = creationDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  const handleClearAccount = async () => {
    try {
      await clearUserAccount(currentUser.uid);
      setShowClearConfirmation(false);
      alert('Your account data has been cleared successfully.');
    } catch (error) {
      console.error('Error clearing account:', error);
      alert('An error occurred while clearing your account. Please try again.');
    }
  };

  return (
    <div className="account-page">
      <div className="account-container">
        <div className="account-sidebar">
          <button 
            className={`sidebar-button ${activeTab === 'account' ? 'active' : ''}`}
            onClick={() => setActiveTab('account')}
          >
            Account
          </button>
          <button 
            className={`sidebar-button ${activeTab === 'changeEmail' ? 'active' : ''}`}
            onClick={() => setActiveTab('changeEmail')}
          >
            Change Email
          </button>
          <button 
            className={`sidebar-button ${activeTab === 'changePassword' ? 'active' : ''}`}
            onClick={() => setActiveTab('changePassword')}
          >
            Change Password
          </button>
          <button 
            className={`sidebar-button ${activeTab === 'clearAccount' ? 'active' : ''}`}
            onClick={() => setActiveTab('clearAccount')}
          >
            Clear Account
          </button>
        </div>
        <div className="account-content">
          {activeTab === 'account' && (
            <>
              <h2>Account Details</h2>
              <div className="account-info">
                <div className="info-item">
                  <span className="info-label">Email:</span>
                  <span className="info-value">{currentUser.email}</span>
                </div>
                <div className="info-item">
                  <span className="info-label">Username:</span>
                  <span className="info-value">{currentUser.displayName || 'Not set'}</span>
                </div>
                <div className="info-item">
                  <span className="info-label">Member since:</span>
                  <span className="info-value">{formattedDate}</span>
                </div>
              </div>
            </>
          )}
          {activeTab === 'changeEmail' && <EmailChangeForm />}
          {activeTab === 'changePassword' && <PasswordChangeForm />}
          {activeTab === 'clearAccount' && (
            <div className="clear-account-section">
              <h2>Clear Account Data</h2>
              <p>This action will remove all your spellbooks, prepared spells, presets, spell notes, spell slots, and settings. This cannot be undone.</p>
              {!showClearConfirmation ? (
                <button className="danger-button" onClick={() => setShowClearConfirmation(true)}>
                  Clear Account Data
                </button>
              ) : (
                <div className="confirmation-dialog">
                  <p>Are you sure you want to clear all your account data?</p>
                  <button className="danger-button" onClick={handleClearAccount}>
                    Yes, Clear My Data
                  </button>
                  <button className="cancel-button" onClick={() => setShowClearConfirmation(false)}>
                    Cancel
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AccountPage;
