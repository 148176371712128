import React from 'react';
import { NavLink } from 'react-router-dom';
import { AllSpellsIcon, BookIcon, CastSpellIcon } from '../data/Icons';
import '../styles/components/TabBar.css';
import crowImage from '../assets/images/crow3.png';
import crowImage2 from '../assets/images/crow2.png';

function TabBar() {
  return (
    <nav className="tab-navigation">
      <img src={crowImage2} alt="Crow" className="tab-crow-image-left" />
      <NavLink to="/spells" className="tab">
        Spell List
        <AllSpellsIcon />
      </NavLink>
      <NavLink to="/spellbooks" className="tab">
        Spellbooks
        <BookIcon />
      </NavLink>
      <NavLink to="/prepared-spells" className="tab">
        Prepared Spells
        <CastSpellIcon />
      </NavLink>
      <img src={crowImage} alt="Crow" className="tab-crow-image" />
    </nav>
  );
}

export default TabBar;
