import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/components/Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h4>D&D Spell Manager</h4>
          <p>Manage your spells with ease</p>
        </div>
        <div className="footer-section">
          <h4>Quick Links</h4>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/spells">Spells</Link></li>
            <li><Link to="/about">About</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Contact</h4>
          <p>Email: support@tablemancer.com</p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 D&D Spell Manager. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;