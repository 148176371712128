import React, { useState } from 'react';
import { changeEmail } from '../firebase/authFunctions';
import '../styles/components/EmailChangeForm.css';
import { ClosedEyeIcon, OpenEyeIcon } from '../data/Icons';

function EmailChangeForm() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      await changeEmail(currentPassword, newEmail);
      setSuccess('Email changed successfully');
      setCurrentPassword('');
      setNewEmail('');
    } catch (error) {
      setError(error.message);
    }
  };

  const handlePasswordVisibility = (event) => {
    if (event.type === 'mousedown' || event.type === 'touchstart') {
      setShowPassword(true);
    } else if (event.type === 'mouseup' || event.type === 'mouseleave' || event.type === 'touchend') {
      setShowPassword(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="email-change-form">
      <h3>Change Email</h3>
      {error && <div className="error-message">{error}</div>}
      {success && <div className="success-message">{success}</div>}
      <div className="input-group">
        <label htmlFor="currentPassword">Current Password</label>
        <div className="password-input">
          <input
            type={showPassword ? "text" : "password"}
            id="currentPassword"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
          />
          <button
            type="button"
            className="password-toggle"
            onMouseDown={handlePasswordVisibility}
            onMouseUp={handlePasswordVisibility}
            onMouseLeave={handlePasswordVisibility}
            onTouchStart={handlePasswordVisibility}
            onTouchEnd={handlePasswordVisibility}
          >
            {showPassword ? <OpenEyeIcon /> : <ClosedEyeIcon />}
          </button>
        </div>
      </div>
      <div className="input-group">
        <label htmlFor="newEmail">New Email</label>
        <input
          type="email"
          id="newEmail"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
          required
        />
      </div>
      <button type="submit" className="submit-btn">Change Email</button>
    </form>
  );
}

export default EmailChangeForm;
