import { useState, useEffect, useMemo } from 'react';
import { 
  filterSpells, 
  getUniqueValues, 
  getComponentOptions, 
  addFilter, 
  removeFilter, 
  clearFilters,
  getActiveFilters
} from '../utils/spellFilters';

const capitalizeFirstLetter = (string) => {
  if (!string || typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

function useSpellFilters(spells, filters, setFilters) {
  const [filteredSpells, setFilteredSpells] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const uniqueLevels = useMemo(() => getUniqueValues(spells, 'level'), [spells]);
  const uniqueSchools = useMemo(() => getUniqueValues(spells, 'school'), [spells]);
  const uniqueClasses = useMemo(() => getUniqueValues(spells, 'classes'), [spells]);
  const componentOptions = useMemo(() => getComponentOptions(spells), [spells]);
  const uniqueSources = useMemo(() => {
    const existingSources = new Set();
    spells.forEach(spell => {
      if (spell.sources) {
        spell.sources.forEach(source => {
          if (source.book) {
            existingSources.add(source.book);
          }
        });
      }
    });
    
    const additionalSources = [
      'Elemental Evil Player\'s Companion',
      '5.0 Free Rules',
      "2024 Player's Handbook 5.0",
      "Sword Coast Adventurer's Guide",
      "Xanathar's Guide to Everything",
      "Tasha's Cauldron of Everything"
    ];
    
    return [...new Set([...existingSources, ...additionalSources])].sort();
  }, [spells]);

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    try {
      const filtered = filterSpells(spells, filters);
      setFilteredSpells(filtered);
    } catch (err) {
      setError('Error filtering spells: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  }, [filters, spells]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      setFilters(clearFilters());
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [setFilters]);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters(prevFilters => addFilter(prevFilters, name, value));
  };

  const handleRemoveFilter = (filterName, value) => {
    setFilters(prevFilters => removeFilter(prevFilters, filterName, value));
  };

  const handleClearFilters = () => {
    setFilters(clearFilters());
  };

  return {
    filters,
    filteredSpells,
    uniqueLevels,
    uniqueSchools,
    uniqueClasses,
    componentOptions,
    uniqueSources,
    handleFilterChange,
    handleRemoveFilter,
    handleClearFilters,
    getActiveFilters,
    isLoading,
    error
  };
}

export default useSpellFilters;