export function formatUnits(value) {
  if (typeof value !== 'string') return value;

  // Regular expression to match numbers followed by 'foot' or 'feet', including cases with radius/cone/sphere/cube/hemisphere/line
  const regex = /(\d+)[-\s]*(foot|feet)(-|\s)?(radius|cone|sphere|cube|hemisphere|line)?/gi;
  
  return value.replace(regex, (match, number, unit, separator, shape) => {
    if (shape) {
      return shape.toLowerCase() === 'line' ? `${number}ft ${shape}` : `${number}ft-${shape}`;
    }
    return `${number}ft`;
  });
}