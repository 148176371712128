// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBDzl3qLEu_eSeBJF55BPkAIBz70UeV50U",
  authDomain: "spellmanagerv2.firebaseapp.com",
  projectId: "spellmanagerv2",
  storageBucket: "spellmanagerv2.appspot.com",
  messagingSenderId: "916008802640",
  appId: "1:916008802640:web:f25f9bc2606040ba243455",
  measurementId: "G-XTH2T03VEB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
