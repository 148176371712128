import { useState, useEffect } from 'react';

function useSearchSpells(spells, searchTerm) {
  const [searchedSpells, setSearchedSpells] = useState(spells);

  useEffect(() => {
    const filtered = spells.filter(spell =>
      spell.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchedSpells(filtered);
  }, [spells, searchTerm]);

  return searchedSpells;
}

export default useSearchSpells;