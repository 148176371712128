import { useState, useCallback, useEffect } from 'react';

// Create an object to store column widths for each tab
const sessionColumnWidths = {};

const useResizableColumns = (initialWidths, tabName = 'spellList') => {
  const storageKey = `spellTableColumnWidths_${tabName}`;
  
  // Initialize state from sessionColumnWidths or use initialWidths
  const [columnWidths, setColumnWidths] = useState(() => {
    if (!sessionColumnWidths[tabName]) {
      sessionColumnWidths[tabName] = initialWidths;
    }
    return sessionColumnWidths[tabName];
  });
  
  const [resizing, setResizing] = useState(null);

  // Add cleanup effect for page refresh
  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionColumnWidths[tabName] = initialWidths;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [storageKey, initialWidths, tabName]);

  const handleResizeStart = useCallback((index, e) => {
    e.preventDefault();
    setResizing({
      index,
      startX: e.pageX,
      startWidth: columnWidths[index]
    });
    
    document.body.style.cursor = 'col-resize';
    document.body.classList.add('resizing');
  }, [columnWidths]);

  const handleResizeMove = useCallback((e) => {
    if (!resizing) return;

    const diff = e.pageX - resizing.startX;
    const newWidth = Math.max(50, resizing.startWidth + diff); // Minimum width of 50px

    setColumnWidths(prev => {
      const newWidths = {
        ...prev,
        [resizing.index]: newWidth
      };
      // Update the session storage
      sessionColumnWidths[tabName] = newWidths;
      return newWidths;
    });
  }, [resizing, tabName]);

  const handleResizeEnd = useCallback(() => {
    setResizing(null);
    document.body.style.cursor = '';
    document.body.classList.remove('resizing');
  }, []);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (resizing) {
        handleResizeMove(e);
      }
    };

    const handleMouseUp = () => {
      if (resizing) {
        handleResizeEnd();
      }
    };

    if (resizing) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [resizing, handleResizeMove, handleResizeEnd]);

  const resetColumnWidths = useCallback(() => {
    setColumnWidths(initialWidths);
    sessionColumnWidths[tabName] = initialWidths;
  }, [initialWidths, tabName]);

  return {
    columnWidths,
    setColumnWidths,
    handleResizeStart,
    resetColumnWidths,
    isResizing: !!resizing
  };
};

export default useResizableColumns; 