import { useCallback, useMemo } from 'react';
import { convertUnits } from '../utils/converUnits';

function useUnitConversion(initialValue, setConvertToMetric) {
  const toggleConversion = useCallback(() => {
    setConvertToMetric(prev => !prev);
  }, [setConvertToMetric]);

  const convertIfNeeded = useMemo(() => {
    return (value) => {
      return initialValue ? convertUnits(value) : value;
    };
  }, [initialValue]);

  return { toggleConversion, convertIfNeeded };
}

export default useUnitConversion;