export const calculateWizardSpells = (level) => {
  if (level < 1 || level > 20) return null;

  // Initialize spell counts
  let spellsByLevel = {
    cantrips: 3, // Start with 3 cantrips
    1: 6, // Start with 6 level 1 spells
  };

  // Add 4th cantrip at level 4
  if (level >= 4) spellsByLevel.cantrips++;
  // Add 5th cantrip at level 10
  if (level >= 10) spellsByLevel.cantrips++;

  // Level 2: +2 level 1 spells
  if (level >= 2) spellsByLevel[1] += 2;

  // Level 3: Access to level 2 spells, +2 level 2 spells
  if (level >= 3) {
    spellsByLevel[2] = 2;
  }

  // Level 4: +2 level 2 spells
  if (level >= 4) {
    spellsByLevel[2] += 2;
  }

  // Level 5: Access to level 3 spells, +2 level 3 spells
  if (level >= 5) {
    spellsByLevel[3] = 2;
  }

  // Level 6: +2 level 3 spells
  if (level >= 6) {
    spellsByLevel[3] += 2;
  }

  // Level 7: Access to level 4 spells, +2 level 4 spells
  if (level >= 7) {
    spellsByLevel[4] = 2;
  }

  // Level 8: +2 level 4 spells
  if (level >= 8) {
    spellsByLevel[4] += 2;
  }

  // Level 9: Access to level 5 spells, +2 level 5 spells
  if (level >= 9) {
    spellsByLevel[5] = 2;
  }

  // Level 10: +2 level 5 spells
  if (level >= 10) {
    spellsByLevel[5] += 2;
  }

  // Level 11: Access to level 6 spells, +2 level 6 spells
  if (level >= 11) {
    spellsByLevel[6] = 2;
  }

  // Level 12: +2 level 6 spells
  if (level >= 12) {
    spellsByLevel[6] += 2;
  }

  // Level 13: Access to level 7 spells, +2 level 7 spells
  if (level >= 13) {
    spellsByLevel[7] = 2;
  }

  // Level 14: +2 level 7 spells
  if (level >= 14) {
    spellsByLevel[7] += 2;
  }

  // Level 15: Access to level 8 spells, +2 level 8 spells
  if (level >= 15) {
    spellsByLevel[8] = 2;
  }

  // Level 16: +2 level 8 spells
  if (level >= 16) {
    spellsByLevel[8] += 2;
  }

  // Level 17: Access to level 9 spells, +2 level 9 spells
  if (level >= 17) {
    spellsByLevel[9] = 2;
  }

  // Level 18: +2 level 9 spells
  if (level >= 18) {
    spellsByLevel[9] += 2;
  }

  // Levels 19-20: +2 spells of any level they have access to
  // For simplicity, we'll add them to the highest available level
  if (level >= 19) {
    spellsByLevel[9] += 2;
  }
  if (level >= 20) {
    spellsByLevel[9] += 2;
  }

  return spellsByLevel;
};

export const getMaxSpellLevel = (level) => {
  if (level < 1) return 0;
  if (level < 3) return 1;
  if (level < 5) return 2;
  if (level < 7) return 3;
  if (level < 9) return 4;
  if (level < 11) return 5;
  if (level < 13) return 6;
  if (level < 15) return 7;
  if (level < 17) return 8;
  return 9;
}; 