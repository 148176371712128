// Helper functions
const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const matchesComponentFilter = (spellComponents, filterValue) => {
  const { verbal, somatic, material } = spellComponents;
  const materialsStr = spellComponents.materials_needed?.join(' ') || '';
  const hasCost = materialsStr.includes(' GP') || materialsStr.includes(' SP');
  
  switch (filterValue) {
    case 'Verbal Component': return verbal;
    case 'Somatic Component': return somatic;
    case 'Material Component': return material;
    case 'Costly Material': return material && hasCost;
    case 'Only Verbal': return verbal && !somatic && !material;
    case 'Only Somatic': return !verbal && somatic && !material;
    default: return true;
  }
};

// Main filter function
export const filterSpells = (spells, filters) => {
  return spells.filter(spell => {
    for (const [field, values] of Object.entries(filters)) {
      if (!values.length) continue;

      if (field === 'components') {
        const spellComponents = spell.components || {};
        if (!values.every(v => matchesComponentFilter(spellComponents, v))) return false;
      }
      else if (field === 'class') {
        if (!Array.isArray(spell.classes)) return false;
        if (!values.some(v => 
          spell.classes.some(c => 
            capitalizeFirstLetter(c) === v || 
            capitalizeFirstLetter(c.name || c) === v
          )
        )) return false;
      }
      else if (field === 'level') {
        const spellLevel = spell.level === 'cantrip' ? 'Cantrip' : spell.level?.toString();
        if (!values.includes(spellLevel)) return false;
      }
      else if (field === 'school') {
        if (!values.includes(capitalizeFirstLetter(spell[field]))) return false;
      }
      else if (field === 'source') {
        if (values.includes('5.0 Free Rules')) {
          if (!spell.srd) return false;
        } else {
          if (!spell.sources?.some(source => values.includes(source.book))) return false;
        }
      }
      else if (field === 'concentration') {
        const isConcentration = spell.duration?.toLowerCase().includes('concentration');
        if (!values.includes(isConcentration ? 'Yes' : 'No')) return false;
      }
      else if (field === 'ritual') {
        const isRitual = spell.ritual === true;
        if (!values.includes(isRitual ? 'Yes' : 'No')) return false;
      }
      else if (!values.includes(spell[field])) return false;
    }
    return true;
  });
};

// Utility functions
export const getUniqueValues = (spells, field) => {
  const values = new Set();
  
  spells.forEach(spell => {
    if (field === 'components') {
      const components = [];
      if (spell.components?.verbal) components.push('Verbal Component');
      if (spell.components?.somatic) components.push('Somatic Component'); 
      if (spell.components?.material) components.push('Material Component');
      if (spell.components?.verbal && !spell.components?.somatic && !spell.components?.material) {
        components.push('Only Verbal');
      }
      if (!spell.components?.verbal && spell.components?.somatic && !spell.components?.material) {
        components.push('Only Somatic');
      }
      components.forEach(c => values.add(c));
    }
    else if (field === 'classes') {
      if (Array.isArray(spell.classes)) {
        spell.classes.forEach(className => {
          const name = typeof className === 'string' ? className : className.name;
          if (name) {
            values.add(capitalizeFirstLetter(name));
          }
        });
      }
    }
    else if (field === 'school') {
      if (typeof spell[field] === 'string') {
        values.add(capitalizeFirstLetter(spell[field]));
      }
    }
    else if (field === 'level') {
      if (spell[field] === 'cantrip') {
        values.add('Cantrip');
      } else if (spell[field] !== undefined && spell[field] !== null) {
        values.add(spell[field].toString());
      }
    }
    else if (field === 'source') {
      if (spell.sources) {
        spell.sources.forEach(source => {
          if (source.book) {
            values.add(source.book);
          }
        });
      }
    }
    else if (spell[field]) {
      values.add(spell[field]);
    }
  });

  // Special sorting for levels
  if (field === 'level') {
    return Array.from(values).sort((a, b) => {
      if (a === 'Cantrip') return -1;
      if (b === 'Cantrip') return 1;
      return parseInt(a) - parseInt(b);
    });
  }

  return Array.from(values).sort();
};

export const getComponentOptions = (spells) => {
  return [
    'Verbal Component',
    'Somatic Component', 
    'Material Component',
    'Costly Material',
    'Only Verbal',
    'Only Somatic'
  ];
};

export const getActiveFilters = (filters) => {
  const active = {};
  Object.entries(filters).forEach(([key, values]) => {
    if (values.length > 0) {
      active[key] = values;
    }
  });
  return active;
};

export const addFilter = (filters, name, value) => {
  const newFilters = { ...filters };
  if (!newFilters[name].includes(value)) {
    newFilters[name] = [...newFilters[name], value];
  }
  return newFilters;
};

export const removeFilter = (filters, name, value) => {
  const newFilters = { ...filters };
  newFilters[name] = newFilters[name].filter(v => v !== value);
  return newFilters;
};

export const clearFilters = () => ({
  class: [],
  level: [],
  school: [],
  components: [],
  concentration: [],
  ritual: [],
  source: []
});

export const prepareFilterOptions = (spells) => ({
  classes: getUniqueValues(spells, 'classes').map(capitalizeFirstLetter),
  levels: getUniqueValues(spells, 'level'),
  schools: getUniqueValues(spells, 'school'),
  components: getComponentOptions(),
  sources: [...new Set([
    ...getUniqueValues(spells, 'source'),
    'Elemental Evil Player\'s Companion',
    '5.0 Free Rules',
    "2024 Player's Handbook 5.0",
    "Sword Coast Adventurer's Guide",
    "Xanathar's Guide to Everything",
    "Tasha's Cauldron of Everything"
  ])].sort()
});

export const getSourceAbbreviation = (source) => {
  const abbreviations = {
    'Elemental Evil Player\'s Companion': 'EEPC',
    '5.0 Free Rules': 'SRD',
    "2024 Player's Handbook 5.0": 'PHB 2024',
    "Sword Coast Adventurer's Guide": 'SCAG',
    "Xanathar's Guide to Everything": 'XGtE',
    "Tasha's Cauldron of Everything": 'TCoE'
  };
  return abbreviations[source] || source;
};

export const getSpellLevel = (level) => level === 0 ? 'Cantrip' : level.toString();

export const getComponents = (spell) => {
  const components = [];
  if (spell.components.verbal) components.push('V');
  if (spell.components.somatic) components.push('S');
  if (spell.components.material) components.push('M');
  return components.join(', ');
};