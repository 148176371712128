import React from 'react';
import '../styles/pages/HowToPage.css';

function HowToPage() {
  return (
    <div className="how-to-container">
      <h1>How to Use the D&D Spell Manager</h1>
      
      <section className="how-to-section">
        <h2>Getting Started</h2>
        <p>Welcome to the D&D Spell Manager! This guide will help you make the most of our spell management system.</p>
        <p>You can use this tool as a guest, but creating an account will allow you to save your spellbooks and settings across devices.</p>
      </section>

      <section className="how-to-section">
        <h2>Main Features</h2>
        
        <div className="feature-block">
          <h3>1. Spell List</h3>
          <p>Browse and search through all available D&D 5e spells. You can:</p>
          <ul>
            <li>Filter spells by level, class, school, and more</li>
            <li>Search spells by name or description</li>
            <li>View detailed spell information</li>
            <li>Add spells to your spellbooks</li>
          </ul>
        </div>

        <div className="feature-block">
          <h3>2. Spellbooks</h3>
          <p>Organize your spells into custom spellbooks:</p>
          <ul>
            <li>Create multiple spellbooks for different characters</li>
            <li>Add or remove spells from your spellbooks</li>
            <li>Switch between different spellbooks easily</li>
            <li>Mark spells as prepared</li>
          </ul>
        </div>

        <div className="feature-block">
          <h3>3. Prepared Spells</h3>
          <p>Manage your prepared spells and spell slots:</p>
          <ul>
            <li>Track which spells you have prepared</li>
            <li>Manage spell slots for each spell level</li>
            <li>Mark spell slots as used or recovered</li>
            <li>Keep track of ritual spells separately</li>
          </ul>
        </div>
      </section>

      <section className="how-to-section">
        <h2>Tips and Tricks</h2>
        <ul>
          <li><strong>Quick Search:</strong> Use the search bar at the top of the spell list to quickly find spells</li>
          <li><strong>Filters:</strong> Combine multiple filters to find exactly what you're looking for</li>
          <li><strong>Spell Details:</strong> Click on any spell to view its full description and details</li>
          <li><strong>Settings:</strong> Customize your experience with settings like metric conversion and short descriptions</li>
        </ul>
      </section>

      <section className="how-to-section">
        <h2>Account Features</h2>
        <p>Creating an account gives you access to:</p>
        <ul>
          <li>Cloud saving of all your spellbooks</li>
          <li>Syncing across multiple devices</li>
          <li>Persistent settings and preferences</li>
          <li>Future premium features</li>
        </ul>
      </section>
    </div>
  );
}

export default HowToPage; 