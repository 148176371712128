import React from 'react';
import '../styles/pages/AboutPage.css';

function AboutPage() {
  return (
    <div className="about-container">
      <h1>Welcome to the Arcane Library</h1>
      
      <section className="about-section">
        <h2>Our Quest</h2>
        <p>In the vast realm of D&D 5e, spellcasters face a mighty challenge: managing their ever-growing collection of magical knowledge. Like a well-organized spellbook, D&D Spell Manager was crafted by fellow adventurers to help magic-wielders focus more on weaving spectacular spells and less on tracking them.</p>
      </section>

      <section className="about-section">
        <h2>Magical Features</h2>
        <div className="feature-grid">
          <div className="feature-card">
            <h3>The Grand Grimoire</h3>
            <p>Access the collected wisdom of the ages with our comprehensive spell archive, containing every official spell from the tomes of D&D 5e. Search and filter with the precision of a master archivist.</p>
          </div>
          <div className="feature-card">
            <h3>Personal Spellbooks</h3>
            <p>Whether you're a Wizard with an ancient tome, a Cleric with divine scrolls, or a Bard composing magical sonnets, create and customize spellbooks for all your characters' arcane needs.</p>
          </div>
          <div className="feature-card">
            <h3>Spell Slot Scrying</h3>
            <p>Track your magical energy as easily as counting gold pieces. Our intuitive spell slot system helps you manage your arcane resources during epic encounters.</p>
          </div>
          <div className="feature-card">
            <h3>Ethereal Storage</h3>
            <p>Like a magical backup scroll, our cloud synchronization ensures your spellbooks are safely stored in the ethereal plane, accessible wherever your adventures take you.</p>
          </div>
        </div>
      </section>

      <section className="about-section">
        <h2>Our Sacred Oath</h2>
        <p>As fellow adventurers, we swear by these principles:</p>
        <ul>
          <li>To maintain our spell archives with the dedication of a master scribe</li>
          <li>To enhance our magical tools based on the wisdom of our fellow adventurers</li>
          <li>To provide a sanctuary for spellcasters of all levels and traditions</li>
          <li>To support the D&D community with tools worthy of the greatest artificers</li>
        </ul>
      </section>

      <section className="about-section">
        <h2>Prophecies of Future Updates</h2>
        <p>Our divination wizards have foreseen many exciting additions to come:</p>
        <ul>
          <li>A forge for crafting your own homebrew spells</li>
          <li>Integration with the ancient tomes of character sheets</li>
          <li>A magical network for sharing spells with your party members</li>
          <li>Portable scrolls (mobile apps) for casting on the go</li>
        </ul>
      </section>

      <section className="about-section">
        <h2>Join Our Adventuring Party</h2>
        <p>This magical tome is maintained by a guild of passionate D&D enthusiasts and code-wielding wizards. Like any good adventure, it's open for fellow questors to join and contribute their own magical expertise.</p>
      </section>

      <section className="about-section">
        <h2>Send a Message via Sending Stone</h2>
        <p>Have you discovered a cursed bug? Dreamed up a new feature during a long rest? Want to join our party? Send us a message through our magical contact portal or seek out our mystical GitHub repository!</p>
      </section>
    </div>
  );
}

export default AboutPage; 