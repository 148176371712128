import { db } from './firebase';
import { doc, getDoc, setDoc, updateDoc, arrayUnion, arrayRemove, deleteField } from 'firebase/firestore';
import spellsData from '../data/spells.json';

// Helper function to convert spell object to spell ID
const spellToId = (spell) => spell.id;

// Helper function to convert spell ID to spell object
const idToSpell = (id) => spellsData.find(spell => spell.id === id);

export const getUserSpellbooks = async (userId) => {
  try {
    if (!userId) {
      console.warn('No user ID provided to getUserSpellbooks');
      return {};
    }

    const userDoc = await getDoc(doc(db, 'users', userId));
    
    // If the document doesn't exist, create it with a default spellbook
    if (!userDoc.exists()) {
      const defaultSpellbooks = { 'My Spellbook': [] };
      await setDoc(doc(db, 'users', userId), {
        spellbooks: defaultSpellbooks
      });
      return defaultSpellbooks;
    }

    const spellbooks = userDoc.data().spellbooks || { 'My Spellbook': [] };
    // Convert spell IDs back to spell objects
    return Object.fromEntries(
      Object.entries(spellbooks).map(([name, spellIds]) => [
        name,
        spellIds.map(idToSpell).filter(Boolean)
      ])
    );
  } catch (error) {
    console.error('Error getting spellbooks:', error);
    // Return a default spellbook on error
    return { 'My Spellbook': [] };
  }
};

export const updateUserSpellbooks = async (userId, spellbooks) => {
  try {
    if (!userId) {
      console.warn('No user ID provided to updateUserSpellbooks');
      return;
    }

    // Convert spell objects to spell IDs
    const spellbooksWithIds = Object.fromEntries(
      Object.entries(spellbooks).map(([name, spells]) => [
        name,
        spells.map(spellToId)
      ])
    );

    const userRef = doc(db, 'users', userId);
    
    // First check if the document exists
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) {
      // If document doesn't exist, create it
      await setDoc(userRef, { spellbooks: spellbooksWithIds });
    } else {
      // If it exists, update it
      await updateDoc(userRef, { spellbooks: spellbooksWithIds });
    }
  } catch (error) {
    console.error('Error updating spellbooks:', error);
    throw error;
  }
};

export const addSpellToSpellbook = async (userId, spellbookName, spell) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      [`spellbooks.${spellbookName}`]: arrayUnion(spell.id)
    });
  } catch (error) {
    console.error('Error adding spell to spellbook:', error);
  }
};

export const removeSpellFromSpellbook = async (userId, spellbookName, spell) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      [`spellbooks.${spellbookName}`]: arrayRemove(spell.id)
    });
  } catch (error) {
    console.error('Error removing spell from spellbook:', error);
  }
};

export const createSpellbook = async (userId, spellbookName) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      [`spellbooks.${spellbookName}`]: []
    });
  } catch (error) {
    console.error('Error creating spellbook:', error);
  }
};

// Add this new function
export const removeSpellbook = async (userId, spellbookName) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      [`spellbooks.${spellbookName}`]: deleteField()
    });
  } catch (error) {
    console.error('Error removing spellbook:', error);
  }
};

// Add these new functions

export const getUserSettings = async (userId) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      return userDoc.data().settings || {};
    }
    return {};
  } catch (error) {
    console.error('Error getting user settings:', error);
    return {};
  }
};

export const updateUserSettings = async (userId, settings) => {
  try {
    await setDoc(doc(db, 'users', userId), { settings }, { merge: true });
  } catch (error) {
    console.error('Error updating user settings:', error);
  }
};

export const getUserPresets = async (userId) => {
  const userDoc = await getDoc(doc(db, 'users', userId));
  const allPresets = userDoc.data()?.presets || {};
  // Return an object where each key is a spellbook name and its value is that spellbook's presets
  return allPresets;
};

export const updateUserPresets = async (userId, spellbookName, presets) => {
  try {
    // Update the presets for the specific spellbook while preserving other spellbooks' presets
    await updateDoc(doc(db, 'users', userId), {
      [`presets.${spellbookName}`]: presets
    });
  } catch (error) {
    console.error('Error updating user presets:', error);
    throw error;
  }
};

// Add these new functions at the end of the file

export const getUserSpellSlots = async (userId) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      return userDoc.data().spellSlotsByBook || {};
    }
    return {};
  } catch (error) {
    console.error('Error getting user spell slots:', error);
    return {};
  }
};

export const updateUserSpellSlots = async (userId, spellbookName, spellSlots) => {
  try {
    await updateDoc(doc(db, 'users', userId), { 
      [`spellSlotsByBook.${spellbookName}`]: spellSlots 
    });
  } catch (error) {
    console.error('Error updating user spell slots:', error);
  }
};

export const clearUserAccount = async (userId) => {
  try {
    const userRef = doc(db, 'users', userId);
    await setDoc(userRef, {
      spellbooks: {},
      presets: {},
      settings: {},
      spellSlotsByBook: {},
      spellNotes: {},
      preparedSpellsByBook: {}
    }, { merge: true });
  } catch (error) {
    console.error('Error clearing user account:', error);
    throw error;
  }
};

export const getUserPreparedSpells = async (userId) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    const preparedSpellsByBook = userDoc.data()?.preparedSpellsByBook || {};
    
    // Convert spell IDs back to spell objects for each spellbook
    return Object.fromEntries(
      Object.entries(preparedSpellsByBook).map(([bookName, spellIds]) => [
        bookName,
        spellIds.map(idToSpell).filter(Boolean)
      ])
    );
  } catch (error) {
    console.error('Error getting prepared spells:', error);
    return {};
  }
};

export const updateUserPreparedSpells = async (userId, spellbookName, preparedSpells) => {
  try {
    // Convert spell objects to IDs for the specific spellbook
    const spellIds = preparedSpells.map(spellToId);
    await updateDoc(doc(db, 'users', userId), { 
      [`preparedSpellsByBook.${spellbookName}`]: spellIds 
    });
  } catch (error) {
    console.error('Error updating prepared spells:', error);
    throw error;
  }
};

// Add these new functions

export const getUserSpellbookOrder = async (userId) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      return userDoc.data().spellbookOrder || ['My Spellbook'];
    }
    return ['My Spellbook'];
  } catch (error) {
    console.error('Error getting spellbook order:', error);
    return ['My Spellbook'];
  }
};

export const updateUserSpellbookOrder = async (userId, order) => {
  try {
    await setDoc(doc(db, 'users', userId), { spellbookOrder: order }, { merge: true });
  } catch (error) {
    console.error('Error updating spellbook order:', error);
  }
};

export const getUserSpellNotes = async (userId, spellId) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      const notes = userDoc.data().spellNotes || {};
      return notes[spellId] || '';
    }
    return '';
  } catch (error) {
    console.error('Error getting spell notes:', error);
    return '';
  }
};

export const updateSpellNote = async (userId, spellId, note) => {
  try {
    const userRef = doc(db, 'users', userId);
    
    // If note is empty, remove the field completely
    if (!note.trim()) {
      await updateDoc(userRef, {
        [`spellNotes.${spellId}`]: deleteField()
      });
    } else {
      // Otherwise, update with the new note
      await setDoc(userRef, {
        spellNotes: {
          [spellId]: note
        }
      }, { merge: true });
    }
  } catch (error) {
    console.error('Error updating spell note:', error);
    throw error;
  }
};

// Add these new functions
export const getUserActiveSpellbook = async (userId) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      return userDoc.data().activeSpellbook || 'My Spellbook';
    }
    return 'My Spellbook';
  } catch (error) {
    console.error('Error getting active spellbook:', error);
    return 'My Spellbook';
  }
};

export const updateUserActiveSpellbook = async (userId, spellbookName) => {
  try {
    await setDoc(doc(db, 'users', userId), { activeSpellbook: spellbookName }, { merge: true });
  } catch (error) {
    console.error('Error updating active spellbook:', error);
  }
};
