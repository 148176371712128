import React, { useState, useMemo, useCallback } from 'react';
import '../styles/components/PreparedSpellsPresets.css';
import { TrashcanIcon } from '../data/Icons';
import spellsData from '../data/spells.json';
import ConfirmationPopup from './ConfirmationPopup';
import { updateUserPresets } from '../firebase/firestoreOperations';

// Helper function to convert spell object to spell ID
const spellToId = (spell) => spell.id;

// Helper function to convert spell ID to spell object
const idToSpell = (id) => spellsData.find(spell => spell.id === id);

function PreparedSpellsPresets({ 
  presets, 
  setPresets,
  onSavePreset, 
  onLoadPreset, 
  onDeletePreset, 
  availableSpells, 
  currentPreset,
  setCurrentPreset,
  preparedSpells,
  currentSpellbook,
  currentUser,
  addNotification
}) {
  const [newPresetName, setNewPresetName] = useState('');
  const [confirmationPopup, setConfirmationPopup] = useState({ isOpen: false, message: '', onConfirm: null });

  // Get only the presets for the current spellbook
  const currentSpellbookPresets = useMemo(() => {
    return presets[currentSpellbook] || {};
  }, [presets, currentSpellbook]);

  const handleSavePreset = () => {
    if (newPresetName.trim()) {
      const spellIds = availableSpells.map(spellToId);
      onSavePreset(currentSpellbook, newPresetName.trim(), spellIds);
      setNewPresetName('');
    }
  };

  const handleLoadPreset = (presetName, spellIds) => {
    const unsavedSpells = preparedSpells.filter(spell => !spellIds.includes(spell.id));
    if (unsavedSpells.length > 0) {
      setConfirmationPopup({
        isOpen: true,
        message: `You have ${unsavedSpells.length} unsaved prepared spell(s). Loading this preset will overwrite your current prepared spells. Do you want to continue?`,
        onConfirm: () => {
          onLoadPreset(currentSpellbook, presetName, spellIds);
          setConfirmationPopup({ isOpen: false, message: '', onConfirm: null });
        }
      });
    } else {
      onLoadPreset(currentSpellbook, presetName, spellIds);
    }
  };

  const validPresets = useMemo(() => {
    return Object.entries(currentSpellbookPresets).map(([name, spellIds]) => {
      const validSpellIds = spellIds.filter(id => availableSpells.some(s => s.id === id));
      return { name, spellIds: validSpellIds };
    });
  }, [currentSpellbookPresets, availableSpells]);

  const deletePreset = useCallback((spellbookName, presetName) => {
    setConfirmationPopup({
      isOpen: true,
      message: `Are you sure you want to delete the preset "${presetName}"?`,
      onConfirm: async () => {
        const newPresets = {
          ...presets[spellbookName]
        };
        delete newPresets[presetName];
        
        if (currentUser) {
          await updateUserPresets(currentUser.uid, spellbookName, newPresets);
        } else {
          const allPresets = JSON.parse(localStorage.getItem('guestPresets') || '{}');
          allPresets[spellbookName] = newPresets;
          localStorage.setItem('guestPresets', JSON.stringify(allPresets));
        }
        
        setPresets(prev => ({
          ...prev,
          [spellbookName]: newPresets
        }));
        
        if (currentPreset === presetName) {
          setCurrentPreset(null);
        }
        
        addNotification(`Preset "${presetName}" deleted from ${spellbookName}`, 'info');
        setConfirmationPopup({ isOpen: false, message: '', onConfirm: null });
      }
    });
  }, [presets, currentUser, currentPreset, setCurrentPreset, setPresets, addNotification]);

  return (
    <div className="prepared-spells-presets-container">
      <div className="prepared-spells-presets">
        <h3>Spell Presets for {currentSpellbook}</h3>
        <div className="preset-actions">
          <input
            type="text"
            value={newPresetName}
            onChange={(e) => setNewPresetName(e.target.value.slice(0, 25))}
            placeholder="Enter preset name"
            maxLength={25}
            className="preset-name-input"
          />
          <button onClick={handleSavePreset}>Save New</button>
        </div>
        <ul className="preset-list">
          {validPresets.map(({ name, spellIds }) => (
            <li 
              key={name} 
              className={currentPreset === name ? 'active' : ''}
              onClick={() => setCurrentPreset(name)}
            >
              <span className="preset-name">{name} ({spellIds.length} spells)</span>
              {currentPreset === name && (
                <div className="preset-buttons">
                  <button onClick={(e) => {
                    e.stopPropagation();
                    handleLoadPreset(name, spellIds);
                  }} className="load-button">
                    Load
                  </button>
                  <button onClick={(e) => {
                    e.stopPropagation();
                    onSavePreset(currentSpellbook, name, availableSpells.map(spellToId));
                  }} className="save-button">
                    Save
                  </button>
                  <button onClick={(e) => {
                    e.stopPropagation();
                    deletePreset(currentSpellbook, name);
                  }} className="delete-button">
                    <TrashcanIcon />
                  </button>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="preset-explanation">
        <h4>How Spell Presets Work</h4>
        <p>Spell Presets allow you to save and quickly load different sets of prepared spells. They are a great way to switch between different spell loadouts for various situations like cities, nature, or dungeons.</p>
        <ol>
          <li>Prepare the spells you want to save as a preset.</li>
          <li>Enter a name for your preset in the input field.</li>
          <li>Click "Save New" to create a new preset.</li>
          <li>To load a preset, click the "Load" button next to its name.</li>
          <li>To update a preset, click the "Save" button.</li>
          <li>To delete a preset, click the trash can icon next to its name.</li>
        </ol>
        <p>Remember, If a spell is no longer found in any of the spellbooks, the spell will also be removed from the preset!</p>
      </div>
      <ConfirmationPopup
        isOpen={confirmationPopup.isOpen}
        message={confirmationPopup.message}
        onConfirm={confirmationPopup.onConfirm}
        onCancel={() => setConfirmationPopup({ isOpen: false, message: '', onConfirm: null })}
      />
    </div>
  );
}

export default PreparedSpellsPresets;
