import DOMPurify from 'dompurify';

export const formatSpellText = (text) => {
  if (!text) return '';
  
  const paragraphs = text.split('\n');
  
  let isInTable = false;
  let currentTable = [];
  const formattedParagraphs = [];

  paragraphs.forEach((paragraph, index) => {
    let formattedText = paragraph.trim();

    // Handle table start
    if (formattedText.startsWith('<table>')) {
      isInTable = true;
      currentTable = [];
      return;
    }

    // Handle table end
    if (formattedText.endsWith('</table>')) {
      isInTable = false;
      // Convert collected table rows into HTML table
      const tableHtml = createTableHtml(currentTable);
      formattedParagraphs.push(tableHtml);
      currentTable = [];
      return;
    }

    // Collect table rows
    if (isInTable) {
      if (formattedText.startsWith('|') && formattedText.endsWith('|')) {
        currentTable.push(formattedText);
      }
      return;
    }

    // Handle regular text
    if (!isInTable) {
      // Handle bullet points
      if (formattedText.startsWith('- ')) {
        formattedText = `<li>${formattedText.substring(2)}</li>`;
        if (!paragraphs.find((p, i) => i < index && p.trim().startsWith('- '))) {
          formattedText = `<ul class="spell-list">${formattedText}`;
        }
        if (!paragraphs.find((p, i) => i > index && p.trim().startsWith('- '))) {
          formattedText = `${formattedText}</ul>`;
        }
      }

      // Handle bold text
      formattedText = formattedText.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
      
      // Handle italic text
      formattedText = formattedText.replace(/\b_(.*?)_\b/g, '<em>$1</em>');
      
      formattedParagraphs.push(DOMPurify.sanitize(formattedText));
    }
  });

  return formattedParagraphs.filter(text => text !== '');
};

const createTableHtml = (tableRows) => {
  if (!tableRows.length) return '';

  const rows = tableRows.map(row => {
    const cells = row
      .split('|')
      .filter(cell => cell.trim() !== '')
      .map(cell => {
        let formattedCell = cell.trim();
        formattedCell = formattedCell.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
        formattedCell = formattedCell.replace(/\b_(.*?)_\b/g, '<em>$1</em>');
        return formattedCell;
      });
    return cells;
  });

  const contentRows = rows.filter(row => 
    !row.every(cell => cell.replace(/-/g, '').trim() === '')
  );

  if (contentRows.length < 2) return '';

  const headers = contentRows[0];
  const dataRows = contentRows.slice(1);

  const isWeatherTable = headers.length === 2 && 
    headers[0].toLowerCase().includes('stage') && 
    headers[1].toLowerCase().includes('condition');

  const tableHtml = `
    <table class="spell-table${isWeatherTable ? ' weather-table' : ''}">
      <thead>
        <tr>
          ${headers.map(header => `<th>${header}</th>`).join('')}
        </tr>
      </thead>
      <tbody>
        ${dataRows.map(row => `
          <tr>
            ${row.map(cell => `<td>${cell}</td>`).join('')}
          </tr>
        `).join('')}
      </tbody>
    </table>
  `;

  return DOMPurify.sanitize(tableHtml);
}; 