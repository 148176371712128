import React, { useState } from 'react';
import '../styles/pages/FAQPage.css';

function FAQPage() {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "What is D&D Spell Manager?",
      answer: "D&D Spell Manager is a web application designed to help Dungeons & Dragons players manage their character's spells, create spellbooks, and track prepared spells and spell slots during gameplay."
    },
    {
      question: "Do I need an account to use the Spell Manager?",
      answer: "No, you can use the Spell Manager as a guest. However, creating an account allows you to save your spellbooks, settings, and preferences across devices, ensuring you never lose your data."
    },
    {
      question: "How do I create a new spellbook?",
      answer: "To create a new spellbook, navigate to the Spellbooks page, click the 'Create New Spellbook' button, and enter a name for your spellbook. You can then add spells from the spell list to your new spellbook."
    },
    {
      question: "Can I prepare spells from multiple spellbooks?",
      answer: "Yes, each spellbook has its own set of prepared spells. You can switch between different spellbooks to manage prepared spells for different characters or scenarios."
    },
    {
      question: "How do spell slots work?",
      answer: "Spell slots are tracked per spellbook in the Prepared Spells section. You can set the maximum number of slots available for each spell level and mark them as used or recovered during gameplay. The system automatically tracks your available slots."
    },
    {
      question: "What happens if I lose internet connection?",
      answer: "If you're using the app as a guest, your data is stored locally in your browser. If you have an account, your data is synchronized with our servers when you're online, and any changes made offline will be synchronized when you reconnect."
    },
    {
      question: "Can I share my spellbooks with other players?",
      answer: "Currently, spellbooks are private to each user. However, we're working on adding sharing features in future updates to allow collaboration between players."
    },
    {
      question: "How do I filter spells by class or level?",
      answer: "In the Spell List view, you'll find filter options on the left side of the screen. You can filter by class, level, school of magic, and other characteristics. Multiple filters can be combined to find exactly what you're looking for."
    },
    {
      question: "Are homebrew spells supported?",
      answer: "Currently, we only support official D&D 5e spells. Support for custom spells is planned for a future update."
    },
    {
      question: "How do I report a bug or request a feature?",
      answer: "You can report bugs or request features through our Contact page. We actively monitor user feedback to improve the application and add new features."
    }
  ];

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <h1>Frequently Asked Questions</h1>
      
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div 
            key={index} 
            className={`faq-item ${openIndex === index ? 'active' : ''}`}
            onClick={() => toggleFAQ(index)}
          >
            <div className="faq-question">
              <h3>{faq.question}</h3>
              <span className="faq-icon"></span>
            </div>
            <div className="faq-answer">
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQPage; 