import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { signup, login, isUserVerified, resendVerificationEmail } from '../firebase/authFunctions';
import { useAuth } from '../AuthContext';
import { ClosedEyeIcon, OpenEyeIcon } from '../data/Icons';
import '../styles/components/Login.css';
import crowImage from '../assets/images/crow1.png';

function Login() {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [resendCooldown, setResendCooldown] = useState(false);
  const [cooldownTime, setCooldownTime] = useState(60);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (currentUser) {
      navigate('/');
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    if (!isLogin) {
      checkPasswordStrength(password);
    }
  }, [password, isLogin]);

  const checkPasswordStrength = (password) => {
    const minLength = 8;
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (password.length >= minLength && hasNumber && hasSpecialChar) {
      setPasswordStrength('strong');
    } else if (password.length >= minLength && (hasNumber || hasSpecialChar)) {
      setPasswordStrength('medium');
    } else if (password.length > 0) {
      setPasswordStrength('weak');
    } else {
      setPasswordStrength('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (isLoading) return;
    
    setError('');
    setSuccess('');
    setIsLoading(true);

    if (!isLogin && passwordStrength !== 'strong') {
      setError('Password does not meet the required criteria');
      setIsLoading(false);
      return;
    }

    try {
      if (isLogin) {
        await login(email, password);
        if (!isUserVerified()) {
          setError("Please verify your email before logging in.");
          setIsLoading(false);
          return;
        }
        navigate('/');
      } else {
        if (password !== confirmPassword) {
          setError("Passwords don't match");
          setIsLoading(false);
          return;
        }
        await signup(email, password, username);
        setSuccess('Registration successful! Please check your email for verification.');
        // Clear form fields after successful registration
        setEmail('');
        setUsername('');
        setPassword('');
        setConfirmPassword('');
        setPasswordStrength('');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleMode = () => {
    setIsLogin(!isLogin);
    setError('');
    setSuccess('');
    setPasswordStrength('');
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setUsername('');
  };

  const handlePasswordVisibility = (event, setVisibility) => {
    if (event.type === 'mousedown' || event.type === 'touchstart') {
      setVisibility(true);
    } else if (event.type === 'mouseup' || event.type === 'mouseleave' || event.type === 'touchend') {
      setVisibility(false);
    }
  };

  const handleResendVerification = async () => {
    if (resendCooldown) return;

    try {
      await resendVerificationEmail();
      setSuccess('Verification email resent. Please check your inbox.');
      setResendCooldown(true);
      
      // Start cooldown timer
      let timeLeft = 60;
      const timer = setInterval(() => {
        timeLeft -= 1;
        setCooldownTime(timeLeft);
        if (timeLeft === 0) {
          setResendCooldown(false);
          clearInterval(timer);
        }
      }, 1000);
    } catch (error) {
      setError(error.message);
    }
  };

  if (currentUser) {
    return null;
  }

  return (
    <div className="login-page">
      <div className="login-container">
        <img src={crowImage} alt="Crow" className="login-crow-image" />
        <h2>{isLogin ? "Login" : "Sign Up"}</h2>
        {error && <div className="error-message">{error}</div>}
        {success && (
          <div className="success-message">
            {success}
            {!isLogin && (
              <div className="resend-verification">
                <button 
                  onClick={handleResendVerification} 
                  disabled={resendCooldown}
                  className="resend-btn"
                >
                  {resendCooldown ? `Resend in ${cooldownTime}s` : "Resend verification email"}
                </button>
              </div>
            )}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          {!isLogin && (
            <div className="input-group">
              <label htmlFor="username">Username</label>
              <input
                id="username"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
          )}
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <div className="password-input">
              <input
                id="password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button
                type="button"
                className="password-toggle"
                onMouseDown={(e) => handlePasswordVisibility(e, setShowPassword)}
                onMouseUp={(e) => handlePasswordVisibility(e, setShowPassword)}
                onMouseLeave={(e) => handlePasswordVisibility(e, setShowPassword)}
                onTouchStart={(e) => handlePasswordVisibility(e, setShowPassword)}
                onTouchEnd={(e) => handlePasswordVisibility(e, setShowPassword)}
              >
                {showPassword ? <OpenEyeIcon /> : <ClosedEyeIcon />}
              </button>
            </div>
            {!isLogin && (
              <div className={`password-strength ${passwordStrength}`}>
                {passwordStrength && `Password strength: ${passwordStrength}`}
              </div>
            )}
            {!isLogin && (
              <div className="password-criteria">
                Password must be at least 8 characters long, contain 1 number and 1 special character.
              </div>
            )}
          </div>
          {!isLogin && (
            <div className="input-group">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <div className="password-input">
                <input
                  id="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  className="password-toggle"
                  onMouseDown={(e) => handlePasswordVisibility(e, setShowConfirmPassword)}
                  onMouseUp={(e) => handlePasswordVisibility(e, setShowConfirmPassword)}
                  onMouseLeave={(e) => handlePasswordVisibility(e, setShowConfirmPassword)}
                  onTouchStart={(e) => handlePasswordVisibility(e, setShowConfirmPassword)}
                  onTouchEnd={(e) => handlePasswordVisibility(e, setShowConfirmPassword)}
                >
                  {showConfirmPassword ? <OpenEyeIcon /> : <ClosedEyeIcon />}
                </button>
              </div>
            </div>
          )}
          <button type="submit" className="submit-btn" disabled={isLoading}>
            {isLogin ? (isLoading ? "Logging in..." : "Login") 
                    : (isLoading ? "Signing up..." : "Sign Up")}
          </button>
        </form>
        <div className="auth-options">
          <button onClick={toggleMode} className="switch-mode-btn">
            {isLogin ? "Need an account? Sign Up" : "Already have an account? Login"}
          </button>
          {isLogin && (
            <Link to="/forgot-password" className="forgot-password-link">
              Forgot Password?
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;
