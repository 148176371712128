import { useState, useEffect } from 'react';

function useCheckboxSelection(items, selectedItems, setSelectedItems) {
  const [areAllSelected, setAreAllSelected] = useState(false);

  useEffect(() => {
    const allSelected = items.every(item => selectedItems[item.name]);
    setAreAllSelected(allSelected);
  }, [items, selectedItems]);

  const toggleItem = (itemName) => {
    setSelectedItems(prev => ({
      ...prev,
      [itemName]: !prev[itemName]
    }));
  };

  const selectAll = (isSelected) => {
    const newSelectedItems = {};
    items.forEach(item => {
      newSelectedItems[item.name] = isSelected;
    });
    setSelectedItems(newSelectedItems);
  };

  const clearSelection = () => {
    setSelectedItems({});
  };

  return { toggleItem, selectAll, areAllSelected, clearSelection };
}

export default useCheckboxSelection;