import React, { useState, createContext, useCallback, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import HomePage from './pages/HomePage';
import SpellDetailPage from './pages/SpellDetailPage';
import SpellList from './components/SpellList';
import SpellBook from './components/SpellBook';
import PreparedSpells from './components/PreparedSpells';
import NavBar from './components/NavBar';
import TabBar from './components/TabBar';
import Footer from './components/Footer';
import { clearFilters } from './utils/spellFilters';
import spellsData from './data/spells.json';
import './App.css';
import { BookIcon, PrepareSpellIcon, AllSpellsIcon } from './data/Icons';
import NotificationPopup from './components/NotificationPopup';
import Login from './components/Login';
import { AuthProvider } from './AuthContext';
import AccountPage from './pages/AccountPage';
import { 
  getUserSpellbooks, 
  updateUserSpellbooks, 
  getUserSettings, 
  updateUserSettings, 
  getUserPreparedSpells, 
  updateUserPreparedSpells, 
  getUserSpellSlots, 
  updateUserSpellSlots,
  getUserActiveSpellbook,
  updateUserActiveSpellbook
} from './firebase/firestoreOperations';
import HowToPage from './pages/HowToPage';
import FAQPage from './pages/FAQPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';

export const NotificationContext = createContext();

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant"
    });
  }, [pathname]);

  return null;
}

function AppContent() {
  const [filters, setFilters] = useState(clearFilters());
  const [selectedSpells, setSelectedSpells] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [convertToMetric, setConvertToMetric] = useState(false);
  const [spellbooks, setSpellbooks] = useState({ 'My Spellbook': [] });
  const [preparedSpellsByBook, setPreparedSpellsByBook] = useState({});
  const [showRitualSpells, setShowRitualSpells] = useState(false);
  const [notification, setNotification] = useState(null);
  const [showShortDescriptions, setShowShortDescriptions] = useState(false);
  const [spellSlotsByBook, setSpellSlotsByBook] = useState({});
  const [currentSpellbook, setCurrentSpellbook] = useState('My Spellbook');
  const [spellListFilters, setSpellListFilters] = useState(clearFilters());
  const [spellbookFilters, setSpellbookFilters] = useState(clearFilters());
  const [preparedSpellsFilters, setPreparedSpellsFilters] = useState(clearFilters());
  const [spellListSortConfig, setSpellListSortConfig] = useState({ key: 'name', direction: 'asc' });
  const [spellbookSortConfig, setSpellbookSortConfig] = useState({ key: 'name', direction: 'asc' });
  const [preparedSpellsSortConfig, setPreparedSpellsSortConfig] = useState({ key: 'name', direction: 'asc' });
  const [spellListPagination, setSpellListPagination] = useState({ itemsPerPage: 25, currentPage: 1 });
  const [spellbookPagination, setSpellbookPagination] = useState({ itemsPerPage: 25, currentPage: 1 });
  const [preparedSpellsPagination, setPreparedSpellsPagination] = useState({ itemsPerPage: 25, currentPage: 1 });

  const { currentUser } = useAuth();
  const location = useLocation();

  const isLoginPage = location.pathname === '/login';
  const isHomePage = location.pathname === '/';
  const isAccountPage = location.pathname === '/account';
  const isContactPage = location.pathname === '/contact';
  const shouldShowTabBar = !isLoginPage && !isHomePage && !isAccountPage && 
                          !location.pathname.includes('/how-to') && 
                          !location.pathname.includes('/faq') && 
                          !location.pathname.includes('/about') &&
                          !isContactPage;

  const handleSpellbookChange = useCallback(async (newSpellbook) => {
    setCurrentSpellbook(newSpellbook);
    if (currentUser) {
      await updateUserActiveSpellbook(currentUser.uid, newSpellbook);
    } else {
      localStorage.setItem('lastActiveSpellbook', newSpellbook);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      const fetchUserData = async () => {
        const userSpellbooks = await getUserSpellbooks(currentUser.uid);
        setSpellbooks(userSpellbooks);

        const userSettings = await getUserSettings(currentUser.uid);
        setShowShortDescriptions(userSettings.showShortDescriptions || false);
        setShowRitualSpells(userSettings.showRitualSpells || false);
        setConvertToMetric(userSettings.convertToMetric || false);

        const userPreparedSpells = await getUserPreparedSpells(currentUser.uid);
        setPreparedSpellsByBook(userPreparedSpells);

        const userSpellSlots = await getUserSpellSlots(currentUser.uid);
        setSpellSlotsByBook(userSpellSlots);

        const activeSpellbook = await getUserActiveSpellbook(currentUser.uid);
        setCurrentSpellbook(activeSpellbook);
      };
      fetchUserData();
    } else {
      const guestSpellbooks = JSON.parse(localStorage.getItem('guestSpellbooks')) || { 'My Spellbook': [] };
      setSpellbooks(guestSpellbooks);
      
      const savedActiveSpellbook = localStorage.getItem('lastActiveSpellbook');
      if (savedActiveSpellbook && guestSpellbooks[savedActiveSpellbook]) {
        setCurrentSpellbook(savedActiveSpellbook);
      } else {
        setCurrentSpellbook('My Spellbook');
      }

      const guestPreparedSpells = JSON.parse(localStorage.getItem('guestPreparedSpellsByBook')) || {};
      setPreparedSpellsByBook(guestPreparedSpells);

      try {
        const savedSlots = localStorage.getItem('spellSlotsByBook');
        if (savedSlots) {
          const parsedSlots = JSON.parse(savedSlots);
          if (Array.isArray(parsedSlots) && parsedSlots.length === 9) {
            setSpellSlotsByBook(parsedSlots.map(slot => ({
              max: Number(slot.max) || 0,
              used: Number(slot.used) || 0
            })));
          } else {
            throw new Error('Invalid spell slots format');
          }
        } else {
          const defaultSlots = Array(9).fill().map(() => ({ max: 0, used: 0 }));
          setSpellSlotsByBook(defaultSlots);
          localStorage.setItem('spellSlotsByBook', JSON.stringify(defaultSlots));
        }
      } catch (error) {
        console.error('Error loading spell slots:', error);
        const defaultSlots = Array(9).fill().map(() => ({ max: 0, used: 0 }));
        setSpellSlotsByBook(defaultSlots);
        localStorage.setItem('spellSlotsByBook', JSON.stringify(defaultSlots));
      }
    }
  }, [currentUser]);

  const addToSpellbook = useCallback((spellbookName, spellsToAdd) => {
    setSpellbooks(prevSpellbooks => {
      const updatedSpellbook = [...(prevSpellbooks[spellbookName] || [])];
      spellsToAdd.forEach(spellName => {
        const spellObject = spellsData.find(spell => spell.name === spellName);
        if (spellObject && !updatedSpellbook.some(s => s.name === spellName)) {
          updatedSpellbook.push(spellObject);
        }
      });
      const newSpellbooks = {
        ...prevSpellbooks,
        [spellbookName]: updatedSpellbook
      };
      if (!currentUser) {
        localStorage.setItem('guestSpellbooks', JSON.stringify(newSpellbooks));
      } else {
        updateUserSpellbooks(currentUser.uid, newSpellbooks);
      }
      return newSpellbooks;
    });
  }, [currentUser]);

  const createSpellbook = async (name) => {
    if (currentUser && !spellbooks[name]) {
      setSpellbooks(prevSpellbooks => {
        const newSpellbooks = {
          ...prevSpellbooks,
          [name]: []
        };
        updateUserSpellbooks(currentUser.uid, newSpellbooks);
        return newSpellbooks;
      });
    }
  };

  const addNotification = useCallback((message, type = 'info', duration = 3000) => {
    setNotification({ message, type, duration });
  }, []);

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification(null);
      }, notification.duration);

      return () => clearTimeout(timer);
    }
  }, [notification]);

  const updateUserSetting = useCallback(async (settingName, value) => {
    if (currentUser) {
      const updatedSettings = { [settingName]: value };
      await updateUserSettings(currentUser.uid, updatedSettings);
    }
  }, [currentUser]);

  const updateShowShortDescriptions = useCallback((value) => {
    setShowShortDescriptions(value);
    updateUserSetting('showShortDescriptions', value);
  }, [updateUserSetting]);

  const updateShowRitualSpells = useCallback((value) => {
    setShowRitualSpells(value);
    updateUserSetting('showRitualSpells', value);
  }, [updateUserSetting]);

  const updateConvertToMetric = useCallback((value) => {
    setConvertToMetric(value);
    updateUserSetting('convertToMetric', value);
  }, [updateUserSetting]);

  const updatePreparedSpells = useCallback((spellbookName, newPreparedSpells) => {
    setPreparedSpellsByBook(prev => ({
      ...prev,
      [spellbookName]: newPreparedSpells
    }));
    
    if (currentUser) {
      updateUserPreparedSpells(currentUser.uid, spellbookName, newPreparedSpells);
    } else {
      localStorage.setItem('guestPreparedSpellsByBook', JSON.stringify({
        ...preparedSpellsByBook,
        [spellbookName]: newPreparedSpells
      }));
    }
  }, [currentUser, preparedSpellsByBook]);

  const updateSpellSlots = useCallback((spellbookName, newSpellSlots) => {
    try {
      setSpellSlotsByBook(prev => ({
        ...prev,
        [spellbookName]: newSpellSlots
      }));
      
      if (currentUser) {
        updateUserSpellSlots(currentUser.uid, spellbookName, newSpellSlots);
      } else {
        localStorage.setItem('spellSlotsByBook', JSON.stringify({
          ...spellSlotsByBook,
          [spellbookName]: newSpellSlots
        }));
      }
    } catch (error) {
      console.error('Error updating spell slots:', error);
    }
  }, [currentUser, spellSlotsByBook]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      setSpellListSortConfig({ key: 'name', direction: 'asc' });
      setSpellbookSortConfig({ key: 'name', direction: 'asc' });
      setPreparedSpellsSortConfig({ key: 'name', direction: 'asc' });
      setSpellListPagination({ itemsPerPage: 25, currentPage: 1 });
      setSpellbookPagination({ itemsPerPage: 25, currentPage: 1 });
      setPreparedSpellsPagination({ itemsPerPage: 25, currentPage: 1 });
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const path = location.pathname;
    let title = 'TableMancer';
    
    switch(path) {
      case '/spells':
        title = 'Spells | TableMancer';
        break;
      case '/spellbooks':
        title = 'Spellbooks | TableMancer';
        break;
      case '/prepared-spells':
        title = 'Prepared Spells | TableMancer';
        break;
      case '/how-to':
        title = 'How To | TableMancer';
        break;
      case '/faq':
        title = 'FAQ | TableMancer';
        break;
      case '/about':
        title = 'About | TableMancer';
        break;
      case '/contact':
        title = 'Contact | TableMancer';
        break;
      case '/login':
        title = 'Login| TableMancer';
        break;
      case '/account':
        title = 'Account | TableMancer';
        break;
      default:
        title = 'TableMancer';
    }
    
    document.title = title;
  }, [location.pathname]);

  return (
    <NotificationContext.Provider value={{ addNotification }}>
      <div className="App">
        <ScrollToTop />
        <NavBar />
        {shouldShowTabBar && (
          <>
            <div className="spell-list-header">
              <div className="spell-list-header-content">
                <h1>Spell Compendium</h1>
                <p>Explore the vast collection of magical spells available in D&D 5e</p>
              </div>
            </div>
            <TabBar />
          </>
        )}
        <div className="content">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/spells" element={
              <div className="route-content">
                <SpellList 
                  filters={spellListFilters}
                  setFilters={setSpellListFilters}
                  selectedSpells={selectedSpells}
                  setSelectedSpells={setSelectedSpells}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  convertToMetric={convertToMetric}
                  setConvertToMetric={updateConvertToMetric}
                  spellbooks={spellbooks}
                  addToSpellbook={addToSpellbook}
                  showShortDescriptions={showShortDescriptions}
                  setShowShortDescriptions={updateShowShortDescriptions}
                  sortConfig={spellListSortConfig}
                  setSortConfig={setSpellListSortConfig}
                  pagination={spellListPagination}
                  setPagination={setSpellListPagination}
                />
              </div>
            } />
            <Route path="/spellbooks" element={
              <div className="route-content">
                <SpellBook 
                  filters={spellbookFilters}
                  setFilters={setSpellbookFilters}
                  selectedSpells={selectedSpells}
                  setSelectedSpells={setSelectedSpells}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  convertToMetric={convertToMetric}
                  setConvertToMetric={updateConvertToMetric}
                  spellbooks={spellbooks}
                  setSpellbooks={setSpellbooks}
                  addToSpellbook={addToSpellbook}
                  createSpellbook={createSpellbook}
                  preparedSpellsByBook={preparedSpellsByBook}
                  updatePreparedSpells={updatePreparedSpells}
                  currentSpellbook={currentSpellbook}
                  setCurrentSpellbook={handleSpellbookChange}
                  showShortDescriptions={showShortDescriptions}
                  setShowShortDescriptions={updateShowShortDescriptions}
                  showRitualSpells={showRitualSpells}
                  setShowRitualSpells={setShowRitualSpells}
                  sortConfig={spellbookSortConfig}
                  setSortConfig={setSpellbookSortConfig}
                  pagination={spellbookPagination}
                  setPagination={setSpellbookPagination}
                />
              </div>
            } />
            <Route path="/prepared-spells" element={
              <div className="route-content">
                <PreparedSpells 
                  filters={preparedSpellsFilters}
                  setFilters={setPreparedSpellsFilters}
                  selectedSpells={selectedSpells}
                  setSelectedSpells={setSelectedSpells}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  convertToMetric={convertToMetric}
                  setConvertToMetric={updateConvertToMetric}
                  preparedSpellsByBook={preparedSpellsByBook}
                  updatePreparedSpells={updatePreparedSpells}
                  spellbooks={spellbooks}
                  currentSpellbook={currentSpellbook}
                  setCurrentSpellbook={handleSpellbookChange}
                  showRitualSpells={showRitualSpells}
                  setShowRitualSpells={setShowRitualSpells}
                  showShortDescriptions={showShortDescriptions}
                  setShowShortDescriptions={updateShowShortDescriptions}
                  spellSlotsByBook={spellSlotsByBook}
                  updateSpellSlots={updateSpellSlots}
                  sortConfig={preparedSpellsSortConfig}
                  setSortConfig={setPreparedSpellsSortConfig}
                  pagination={preparedSpellsPagination}
                  setPagination={setPreparedSpellsPagination}
                />
              </div>
            } />
            <Route path="/spell/:id" element={
              <div className="route-content">
                <SpellDetailPage 
                  convertToMetric={convertToMetric}
                  setConvertToMetric={updateConvertToMetric}
                  spellbooks={spellbooks}
                  addToSpellbook={addToSpellbook}
                />
              </div>
            } />
            <Route path="/login" element={<Login />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/how-to" element={
              <div className="route-content">
                <HowToPage />
              </div>
            } />
            <Route path="/faq" element={
              <div className="route-content">
                <FAQPage />
              </div>
            } />
            <Route path="/about" element={
              <div className="route-content">
                <AboutPage />
              </div>
            } />
            <Route path="/contact" element={
              <div className="route-content">
                <ContactPage />
              </div>
            } />
          </Routes>
        </div>
        {notification && (
          <NotificationPopup
            message={notification.message}
            type={notification.type}
            duration={notification.duration}
          />
        )}
        <Footer />
      </div>
    </NotificationContext.Provider>
  );
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
}

export default App;
